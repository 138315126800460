import React, { useEffect, useRef, useState } from 'react';
import './Select.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSelectList } from '../Select/select.slice';
import { host } from '../../App';
import closeIcon from '../../images/close.png';
import { setElement, setForwardedFromElementWindow } from '../Element/element.slice';

import rightFolder from '../../images/right-folder.png';
import ImageComponent from '../ImageComponent/ImageComponent';
import { useLocation, useNavigate } from 'react-router-dom';

function Select({ property, props, data, action, setElementState }) {
    const dispatch = useDispatch();
    const [listShowed, setListShowed] = useState(false);
    const allPages = useSelector(state => state.pages.allPages);

    const location = useLocation();
    useEffect(() => {
        if (location.search.includes('sid_') === false && listShowed === true) {
            setListShowed(false);
            setSelectListFolder(selectList?.items?.filter(item => item?.folder === undefined).sort((a, b) => (a?.menuOrderLevel ?? 5) - (b?.menuOrderLevel ?? 5)).sort((a, b) => a?.isFolder === true && b?.isFolder !== true ? -1 : 1));
            return;
        }

        if (location.search.includes('sid_' + property) === true && listShowed === false) {
            setListShowed(true);
        }
    }, [location.search]);
    useEffect(() => {
        if (listShowed) {
            const listItems = document.getElementsByClassName('select-absolute-list-item');
            if (listItems[0]) {
                listItems[0].focus();
            }
            dispatch(setForwardedFromElementWindow(true));
        }
    }, [listShowed]);
    const [selectAbsoluteListShowed, setSelectAbsoluteListShowed] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);

    const [selectElement, setSelectElement] = useState();
    const [selectListFolder, setSelectListFolder] = useState([]);

    const onInputChange = (e) => {
        if (e.target.value === '') {
            setFilteredItems([]);
            setSelectAbsoluteListShowed(false);
            return;
        }
        let results = selectList.items.filter(item => item[selectList.pageData.listTitle]?.toString().trim().toLowerCase().startsWith(e.target.value.trim().toLowerCase()));
        results = results.concat(selectList.items.filter(item => item[selectList.pageData.listTitle]?.toString().trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) && !results.find(rItem => rItem[selectList.pageData.listTitle] === item[selectList.pageData.listTitle])));
        setFilteredItems(results.filter(item => item?.isFolder !== true));
        setSelectAbsoluteListShowed(true);
    }

    const selectList = useSelector(state => state.select.selectLists[props.collection]);
    useEffect(() => {
        const collectionPage = allPages.find(page => page.collection === props.collection);
        dispatch(fetchSelectList(props.collection + '/' + localStorage.getItem('token') + '?pagination=false&fullList=true' + (collectionPage?.isShopOrientedPage === true ? '&shop_id=' + localStorage.getItem('shop_id') : '')));
    }, [dispatch, props?.collection]);

    useEffect(() => {
        if (selectList && selectList.items) {
            setSelectElement(selectList.items.find(item => item?._id === data?._id));
            setSelectListFolder(selectList.items.filter(item => item?.folder === undefined).sort((a, b) => (a?.menuOrderLevel ?? 5) - (b?.menuOrderLevel ?? 5)).sort((a, b) => a?.isFolder === true && b?.isFolder !== true ? -1 : 1));
        }
    }, [selectList?.status]);
    const onSelect = (element) => {
        if (element?.isFolder === true) {
            setSelectListFolder(selectList.items.filter(item => item.folder?._id === element?._id).sort((a, b) => (a?.menuOrderLevel ?? 5) - (b?.menuOrderLevel ?? 5)).sort((a, b) => a?.isFolder === true && b?.isFolder !== true ? -1 : 1));
            return;
        }
        setSelectElement(element);
        setSelectListFolder(selectList.items.filter(item => item?.folder === undefined).sort((a, b) => (a?.menuOrderLevel ?? 5) - (b?.menuOrderLevel ?? 5)).sort((a, b) => a?.isFolder === true && b?.isFolder !== true ? -1 : 1));
        dispatch(setForwardedFromElementWindow(true));

        const addObject = { _id: element._id };
        if (props?.collection?.startsWith('select_')) {
            addObject.value = element.value;
        }

        if (typeof setElementState === 'function') {
            if (location.search.includes('sid_') === true) {
                navigate(-1);
            }
            setElementState(property, addObject);
            setListShowed(false);
            return;
        }
        if (location.search.includes('sid_') === true) {
            navigate(-1);
        }
        dispatch(setElement({ property, value: addObject }));
        setListShowed(false);
    }
    const keyDown = e => {
        const index = Array.from(document.getElementsByClassName('select-absolute-list-item')).indexOf(document.activeElement);
        if (e.key === 'ArrowDown') {
            if (!document.activeElement.classList.contains('select-absolute-list-item') && document.getElementsByClassName('select-absolute-list-item')[0] !== undefined) {
                document.getElementsByClassName('select-absolute-list-item')[0].focus();
                return;
            }
            if (index < document.getElementsByClassName('select-absolute-list-item').length - 1) {
                document.getElementsByClassName('select-absolute-list-item')[index + 1].focus();
            }
            return;
        }
        if (e.key === 'ArrowUp') {
            if (document.activeElement.classList.contains('select-absolute-list-item')) {
                if (index > 0) {
                    document.getElementsByClassName('select-absolute-list-item')[index - 1].focus();
                }
            }
            return;
        }
        if (e.key === 'Enter') {
            const listActiveElement = document.getElementsByClassName('select-absolute-list-item')[index];
            if (listActiveElement) {
                listActiveElement.click();
            }
            return;
        }
    }
    const selectInputRef = useRef();
    const navigate = useNavigate();
    return (
        <div className='full-width'>
            {
                (action !== 'view' && listShowed === true) &&
                <div className='select-list'>
                    <div className='element-header flex-space-between select-element-header'>
                        <h3 className="element-title">{props.label}</h3>
                        <button className="button cancel" id='select-list-cancel' onClick={() => { dispatch(setForwardedFromElementWindow(true)); setListShowed(false); setSelectListFolder(selectList.items.filter(item => item?.folder === undefined).sort((a, b) => (a?.menuOrderLevel ?? 5) - (b?.menuOrderLevel ?? 5)).sort((a, b) => a?.isFolder === true && b?.isFolder !== true ? -1 : 1)); navigate(-1) }}>Отмена</button>
                    </div>
                    {
                        selectList.status === 'success' &&
                        selectListFolder.map((item, index) =>
                            <div key={index} tabIndex={0} className='list-item flex-start align-center select-absolute-list-item' style={{ height: '55px' }} onClick={() => onSelect(item)} onKeyDown={keyDown}>

                                {
                                    item?.icon &&
                                    <div>
                                        <ImageComponent src={item?.icon ? host.url + '/api/' + item.icon : ''} alt="" width={50} className='align-center flex-center rounded' style={{ marginRight: '5px' }} />
                                    </div>
                                }

                                <p className='select-list-item'>
                                    {item.title}
                                </p>

                                {
                                    item?.isFolder &&
                                    <ImageComponent style={{ position: 'absolute', right: 5 }} className={'rounded'} src={rightFolder} width={24} />
                                }
                            </div>
                        )
                    }
                    {
                        (selectList.status === 'success' && selectListFolder.length === 0) &&
                        <p className="description text-center flex-center">Нет элементов</p>
                    }
                    {
                        selectList.status === 'loading' &&
                        Array.from({ length: 10 }).map((_, i) =>
                            <div key={i} className='list-item flex-start align-center loading' style={{ height: '40px' }}>
                                <p key={i} className='select-list-item loading'>

                                </p>
                            </div>
                        )
                    }
                    {
                        selectList.status === 'error' &&
                        <p className='error' style={{ height: '80px' }}>
                            При загрузки элементов произошла ошибка
                        </p>
                    }
                </div>
            }
            {
                listShowed === false &&
                <div className='text-view flex-column full-width'>
                    <p className="label-view full-width"><span className='required'>{(props.required && action !== 'view') ? '*' : ''}</span>{props.label}</p>
                    {
                        action !== 'view' &&
                        <div className='full-width'>
                            <div className='flex-space-between background-color-light rounded'>
                                {
                                    selectElement === undefined &&
                                    <div style={{ width: '100%' }}>
                                        <input placeholder={props.placeholder ?? ''} ref={selectInputRef} type="text" className='no-background-color full-width' onChange={onInputChange} onKeyDown={keyDown} />
                                        {
                                            selectAbsoluteListShowed === true &&
                                            <div className='select-absolute-list'>
                                                {
                                                    selectList.status === 'success' &&
                                                    filteredItems.map((item, index) =>
                                                        <div key={index} tabIndex={0} className='list-item flex-start align-center select-absolute-list-item' style={{ height: '40px' }} onClick={() => onSelect(item)} onKeyDown={keyDown}>
                                                            {
                                                                item?.icon &&
                                                                <img src={item?.icon ? host.url + '/api/' + item.icon : ''} alt="" width={20} style={{ marginRight: '5px' }} className='rounded' />
                                                            }
                                                            <p onClick={() => {
                                                                typeof setElementState === 'function' && setElementState(item);
                                                                setListShowed(false);
                                                            }} className='select-list-item'>
                                                                {item.title}
                                                            </p>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    (selectList.status === 'success' && filteredItems.length === 0) &&
                                                    <p className="description text-center flex-center">Нет элементов</p>
                                                }
                                                {
                                                    selectList.status === 'loading' &&
                                                    Array.from({ length: 10 }).map((_, i) =>
                                                        <div key={i} className='list-item flex-start align-center loading' style={{ height: '40px' }}>
                                                            <p key={i} className='select-list-item loading'></p>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    selectList.status === 'error' &&
                                                    <p className='error' style={{ height: '40px' }}>
                                                        При загрузки элементов произошла ошибка
                                                    </p>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    selectElement !== undefined &&
                                    <div tabIndex={0} className='list-item flex-start align-center' style={{ height: '55px', maxWidth: 'calc(100% - 90px)' }} onClick={() => { setListShowed(true); navigate((location.search.includes('?') ? location.search + '&' : '?') + 'sid_' + property + '=1') }} onKeyDown={e => { e.key === 'Enter' && e.target.click() }}>
                                        {
                                            selectElement?.icon &&
                                            <ImageComponent src={selectElement?.icon ? host.url + '/api/' + selectElement.icon : ''} width={50} style={{ marginRight: '5px' }} className={'rounded'} />
                                        }
                                        <p onClick={() => {
                                            typeof setElementState === 'function' && setElementState(selectElement);
                                            setListShowed(false);
                                        }} className='select-list-item'>
                                            {selectElement.title}
                                        </p>
                                    </div>
                                }
                                <div className='flex-center align-center'>
                                    <button className="button no-background-color flex-center align-center" style={{ display: selectElement ? 'flex' : 'none', borderRadius: '0 5px 5px 0', backgroundColor: 'var(--primary-background-color-light' }} onClick={() => { setSelectElement(); typeof setElementState === 'function' ? setElementState(property, undefined) : dispatch(setElement({ property, value: undefined })) }}><img src={closeIcon} alt="" width={25} /></button>
                                    <button className='button no-background-color' onClick={() => { setListShowed(true); navigate((location.search.includes('?') ? location.search + '&' : '?') + 'sid_' + property + '=1'); }}>
                                        <img src={rightFolder} alt="" width={25} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        action === 'view' &&
                        <div className='flex-start full-width'>
                            <div tabIndex={0} className='flex-start align-center full-width' style={{ height: '55px' }}>
                                {
                                    selectElement?.icon &&
                                    <ImageComponent src={selectElement?.icon ? host.url + '/api/' + selectElement.icon : ''} alt="" width={50} style={{ marginRight: '5px' }} className='rounded' />
                                }
                                <p className='select-list-item'>
                                    {selectElement?.title}
                                </p>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Select