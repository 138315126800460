import React, { useEffect, useRef, useState } from 'react';
import './ShopSwitchter.css';
import { useDispatch, useSelector } from 'react-redux';
import ImageComponent from '../ImageComponent/ImageComponent';
import { setShop } from '../Shop/shop.slice';
import { host } from '../../App';
import minusIcon from '../../images/minus.png';
import shopAvatar from '../../images/shop-avatar.png';


function ShopSwitchter({ dispathcher, onSwitch }) {
  const dispatch = useDispatch();
  const { shops, status, shop } = useSelector((state) => state.shop);

  useEffect(() => {
    if (status === 'success') {
      const activeShop = shops.find(shopFind => shopFind?._id === localStorage.getItem('shop_id'));
      (activeShop !== undefined && shop === undefined) && dispatch(setShop(activeShop));
    }
  }, [dispatch, status, shops]);

  const [shopsListShowed, setShopsListShowed] = useState(false);

  const switchterElementRef = useRef(null);
  const switchterListRef = useRef(null);

  const [shopSwitchterStyle, setShopSwitchterStyle] = useState();

  return (
    <div className="shop-switchter-container">
      {
        (status === 'success' && shops?.length > 1) &&
        <div ref={switchterElementRef}>
          {
            <button className="shop-switchter"
              onClick={
                () => {
                  setShopSwitchterStyle(
                    {
                      top: document.body.clientHeight - switchterElementRef.current?.getBoundingClientRect().top > switchterListRef.current?.getBoundingClientRect().height ? switchterElementRef.current?.getBoundingClientRect().top + switchterElementRef.current.getBoundingClientRect().height : 'auto',
                      bottom: document.body.clientHeight - switchterElementRef.current?.getBoundingClientRect().top > switchterListRef.current?.getBoundingClientRect().height ? 'auto' : '5px',
                      left: document.body.clientWidth - switchterElementRef.current?.getBoundingClientRect().left > switchterListRef.current?.getBoundingClientRect().width ? switchterElementRef.current?.getBoundingClientRect().left : 'auto',
                      right: document.body.clientWidth - switchterElementRef.current?.getBoundingClientRect().left > switchterListRef.current?.getBoundingClientRect().width ? 'auto' : '5px',
                    }
                  );
                  setTimeout(() => {
                    setShopsListShowed(true);
                  }, 50);
                }
              }
            >
              <div className='flex-center align-center'>
                <ImageComponent src={shop?.images?.[0]?.src ? host.url + '/api/' + shop?.images?.[0]?.src : shopAvatar} width={25} height={25} style={{ borderRadius: '50%' }} />
              </div>
              <div className='small-left-margin'>
                <p className="shop-switchter-text">{shop?.title}</p>
              </div>
              <div className='small-left-margin flex-center align-center'>
                <ImageComponent src={minusIcon} width={15} height={15} />
              </div>
            </button>
          }
          <div className='shop-switchter-list-container' style={{ visibility: shopsListShowed === true ? 'visible' : 'hidden' }} onClick={() => setShopsListShowed(false)}>
            <div className="shop-switchter-list" style={{ ...shopSwitchterStyle }} ref={switchterListRef}>
              {                
                <div style={{ visibility: shopsListShowed === true ? 'visible' : 'hidden' }}>
                  {
                    shops.map((mShop, index) => (
                      <button
                        key={index}
                        className='shop-switchter-list-item'
                        onClick={() => { dispatch(setShop(mShop)); setShopsListShowed(false); dispathcher && dispathcher(); onSwitch && onSwitch(); }}
                      >
                        <div className='flex-center align-center'>
                          <ImageComponent src={mShop?.images?.[0]?.src ? host.url + '/api/' + mShop?.images?.[0]?.src : shopAvatar} width={25} height={25} style={{ borderRadius: '50%' }} />
                        </div>
                        <div className='small-left-margin'>
                          <p className="shop-switchter-text">{mShop?.title}</p>
                          <p className="shop-switchter-text shop-switchter-text-note">{mShop?.note}</p>
                        </div>
                        {
                          shop?._id === mShop?._id &&
                          <span className='active-shop-indicator'>●</span>
                        }
                      </button>
                    ))
                  }
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default ShopSwitchter