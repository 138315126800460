import React, { useRef } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

function ChartComponent({ data, props, className }) {
    const chartContainerRef = useRef(null);
    const { height, width, legend, legendFormatter, label, label2, lineDataKey, lineDataKey2, xAxisDataKey, yAxisDataKey, CustomTooltip, domain } = props;
    return (
        <div ref={chartContainerRef} className={(className ?? '') + " chart"} style={{ height: height ?? '300px', width: width ?? '100%' }}>
            {
                data.length > 0 &&
                <ResponsiveContainer>
                    <LineChart width={chartContainerRef.current?.offsetWidth} height={chartContainerRef.current?.offsetHeight} data={data} margin={{ top: 25, right: 55, bottom: 25, left: 55 }}>
                        <Line label={label} type="monotone" dataKey={lineDataKey} stroke="#0076FE" strokeWidth={2} />
                        {lineDataKey2 && <Line label={label2} type="monotone" dataKey={lineDataKey2} stroke="#F46565" strokeWidth={2} />}
                        {legend === true && <Legend height={40} formatter={formatter => legendFormatter ? legendFormatter(formatter) : formatter} iconSize={16} />}
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey={xAxisDataKey ?? ''} />
                        <YAxis dataKey={yAxisDataKey ?? ''} domain={domain ?? undefined} />
                        <Tooltip content={CustomTooltip ? <CustomTooltip /> : null} />
                    </LineChart>
                </ResponsiveContainer>
            }
            {
                data.length === 0 &&
                <div className='description full-width flex-center align-center' style={{ height: '100%' }}>Нет данных для отображения</div>
            }
        </div>
    )
}

export default ChartComponent