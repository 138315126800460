import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchSelectList = createAsyncThunk("select/fetchSelectList", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const selectSlice = createSlice({
    name: "select",
    initialState: {
        selectLists: {},
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSelectList.pending, (state, action) => {
                const collection = action.meta.arg.split("/")[0];
                state.selectLists[collection] = {};
                state.selectLists[collection].items = [];
                state.selectLists[collection].status = "loading";
            });

        builder
            .addCase(fetchSelectList.fulfilled, (state, action) => {
                const collection = action.meta.arg.split("/")[0];
                state.selectLists[collection] = {};
                state.selectLists[collection].items = action.payload.data;
                state.selectLists[collection].pageData = action.payload.page;
                state.selectLists[collection].status = "success";
            });

        builder
            .addCase(fetchSelectList.rejected, (state, action) => {
                const collection = action.meta.arg.split("/")[0];
                state.selectLists[collection] = {};
                state.selectLists[collection].items = [];
                state.selectLists[collection].status = "error";
            });
    },
});

export default selectSlice.reducer;