import React from 'react';
import './Date.css';
import { setElement } from '../Element/element.slice';
import { useDispatch } from 'react-redux';


function DateTimeComponent({ data, props, action, property, setElementState }) {
  const dispatch = useDispatch();
  return (
    <div>
      {
        action === 'view' &&
        <div className='text-view'>
          <p className="label-view">{props?.label}</p>
          <p className="secondary-text-view">{data !== undefined ? new Date(data).toLocaleString() : null}</p>
        </div>
      }
      {
        (action === 'add' || action === 'edit' || action === 'copy') &&
        <div className='text-view flex-column'>
          <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
          <input type="datetime-local" value={(data !== undefined ? new Date(data).toISOString().slice(0, 21) : '')} onChange={e => setElementState ? setElementState(property, new Date(e.target.value)) : dispatch(setElement({ property, value: new Date(e.target.value) }))} />
        </div>
      }
    </div>
  )
}

export default DateTimeComponent