import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Button.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubmitElement, setIsComplated } from '../Element/element.slice';

function Button({ props, action }) {
    const navigate = useNavigate();
    const { element, page } = useSelector(state => state.element);
    const handleButtonClick = e => {
        switch (action) {
            case 'cancel': navigate(-1); break;
            case 'submit': submit(); break;
            default: break;
        }
    }
    const dispatch = useDispatch();
    const submit = () => {
        let isComplate = true;
        Object.entries(page.components).forEach(([key, value]) => {
            if (value.required === true && (element[key] === undefined || element[key].toString().trim() === '')) {
                if (value.filterItem !== undefined && value.filterValues !== undefined && element[value.filterItem] !== undefined && !value.filterValues.includes(element[value.filterItem])) {
                    return;
                }
                isComplate = false;
            }
        })
        if (!isComplate) {
            dispatch(setIsComplated(isComplate));
            setTimeout(() => {
                dispatch(setIsComplated(true));
            }, 5000);
            return;
        }
        dispatch(fetchSubmitElement({ path: props.path, body: props.body }));
    };
    return (
        <button className={'button form-button' + (action === 'cancel' ? ' cancel' : action === 'submit' ? ' accept' : '')} onClick={handleButtonClick}>{props.label}</button>
    )
}

export default Button