import React, { useEffect, useState } from 'react';
import Chart from '../Chart/Chart';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIncomingGoods, fetchSalesVolume } from './widgets.slice';
import { splitValue } from '../Number/Number';
import Loader from '../Loader/Loader';

function SalesVolumeChart({ props }) {
    const { title: productName } = props;
    const dispatch = useDispatch();
    const { status, items } = useSelector(state => state.widgets.salesVolumeChart);
    const { status: incomingGoodsStatus, items: incomingGoodsItems } = useSelector(state => state.widgets.incomingGoods);
    const { shop } = useSelector(state => state.shop);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const shop_id = localStorage.getItem('shop_id');
        if (items.length === 0) {
            dispatch(fetchSalesVolume(token + '?shop_id=' + shop_id + '&pagination=false'));    
        }

        if (incomingGoodsItems.length === 0) {
            dispatch(fetchIncomingGoods(token + '?shop_id=' + shop_id + '&pagination=false'));
        }
    }, [dispatch]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const shop_id = localStorage.getItem('shop_id');
        dispatch(fetchSalesVolume(token + '?shop_id=' + shop_id + '&pagination=false'));
        dispatch(fetchIncomingGoods(token + '?shop_id=' + shop_id + '&pagination=false'));
    }, [shop]);


    const [itemsState, setItemsState] = useState([]);
    useEffect(() => {
        if (status === 'success') {
            setItemsState(analyzeSales(items, incomingGoodsItems, productName));
        }
    }, [status, items, productName]);

    const analyzeSales = (sales, incomeGoods, productName) => {
        const groupedSales = {};
        const today = new Date();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();

        // Russian names for months
        const monthsOfYear = [
            "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
            "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
        ];

        // Group sales by month and year
        sales.forEach(sale => {
            // Check if the specified product exists in the goods array
            const product = sale.goods.find(good => good.goods.text.trim() === productName.trim());

            if (product) {
                const saleDate = new Date(sale.date);
                const monthYearKey = `${saleDate.getFullYear()}-${saleDate.getMonth()}`;

                if (!groupedSales[monthYearKey]) {
                    groupedSales[monthYearKey] = {
                        totalWeight: 0,
                        incomeWeight: 0,
                        month: monthsOfYear[saleDate.getMonth()],
                        year: saleDate.getFullYear(),
                        monthYearDate: new Date(saleDate.getFullYear(), saleDate.getMonth(), 1),
                        daysInMonth: saleDate.getMonth() === currentMonth && saleDate.getFullYear() === currentYear
                            ? today.getDate() // Use current day if it's the current month
                            : new Date(saleDate.getFullYear(), saleDate.getMonth() + 1, 0).getDate() // Total days in month
                    };
                }

                // Accumulate the weight of the specified product
                groupedSales[monthYearKey].totalWeight += product.quantity * product.coefficient; // Use the weight property
            }
        });

        incomeGoods.forEach(sale => {
            // Check if the specified product exists in the goods array
            const product = sale.goods.find(good => good.goods.text.trim() === productName.trim());

            if (product) {
                const saleDate = new Date(sale.date);
                const monthYearKey = `${saleDate.getFullYear()}-${saleDate.getMonth()}`;

                if (!groupedSales[monthYearKey]) {
                    groupedSales[monthYearKey] = {
                        totalWeight: 0,
                        incomeWeight: 0,
                        month: monthsOfYear[saleDate.getMonth()],
                        year: saleDate.getFullYear(),
                        monthYearDate: new Date(saleDate.getFullYear(), saleDate.getMonth(), 1),
                        daysInMonth: saleDate.getMonth() === currentMonth && saleDate.getFullYear() === currentYear
                            ? today.getDate() // Use current day if it's the current month
                            : new Date(saleDate.getFullYear(), saleDate.getMonth() + 1, 0).getDate() // Total days in month
                    };
                }

                // Accumulate the weight of the specified product
                groupedSales[monthYearKey].incomeWeight += product.quantity * product.coefficient; // Use the weight property
            }
        });

        // Prepare the result array
        const result = Object.values(groupedSales).map(({ totalWeight, incomeWeight, month, year, daysInMonth, monthYearDate }) => {
            const averageWeightPerDay = totalWeight / daysInMonth; // Calculate average per day in month
            return {
                monthYear: `${month} ${year}`,
                averageWeightPerDay: averageWeightPerDay.toFixed(0), // Format to 2 decimal places
                totalWeight: totalWeight.toFixed(0),
                incomeWeight: incomeWeight.toFixed(0),
                monthYearDate: monthYearDate
            };
        });

        // Calculate average for the current month
        // const currentMonthKey = `${currentYear}-${currentMonth}`;
        // if (groupedSales[currentMonthKey]) {
        //     // const { totalWeight } = groupedSales[currentMonthKey];
        //     // const daysInCurrentMonth = today.getDate(); // Days from the start of the month until today
        //     // const averageCurrentMonthWeight = totalWeight / daysInCurrentMonth;

        //     // Update the current month entry in the result
        //     // const currentMonthEntry = result.find(entry => entry.monthYear === `${monthsOfYear[currentMonth]} ${currentYear}`);
        //     // if (currentMonthEntry) {
        //     //     currentMonthEntry.averageWeightPerDay = averageCurrentMonthWeight.toFixed(2);
        //     // }
        // }

        return result.sort((a, b) => a.monthYearDate - b.monthYearDate);
    };

    function CustomTooltip({ payload, label, active }) {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', color: '#0076FE', border: '1px solid var(--primary-border-color)', padding: '5px' }}>
                    <p style={{ padding: 0, margin: 0, marginBottom: '5px', color: 'var(--primary-color)', fontWeight: 'bold' }}>{payload[0]?.payload?.monthYear}</p>
                    <p style={{ padding: 0, margin: 0, marginBottom: '5px', color: '#F46565' }}>{'Реализации'}</p>
                    <p style={{ padding: 0, margin: 0, fontWeight: 'bold', color: '#F46565' }}>{splitValue(payload[1]?.value) + ' кг'}</p>
                    <p style={{ padding: 0, margin: 0, marginBottom: '5px' }}>{'Поступления'}</p>
                    <p style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>{splitValue(payload[0]?.value) + ' кг'}</p>
                </div>
            );
        }

        return null;
    }
    const maxValue = Math.max(...itemsState.map(item => item["totalWeight"]), ...itemsState.map(item => item["incomeWeight"]));
    
    return (
        <div className={'collection'}>
            <p className="label-view">{props.label}</p>
            {
                status === "loading" &&
                <Loader style={{ height: '240px', width: '100%', marginBottom: '60px' }} />
            }
            {
                (status === "success" && incomingGoodsStatus === "success") &&
                <Chart
                    data={itemsState}
                    props={
                        {
                            lineDataKey: "incomeWeight",
                            lineDataKey2: "totalWeight",
                            label: 'Реализация',
                            label2: 'Поступление',
                            legendFormatter: (value, entry, index) => value === 'incomeWeight' ? 'Поступление' : 'Реализация',
                            xAxisDataKey: "monthYear",
                            legend: true,
                            height: '350px',
                            CustomTooltip: CustomTooltip,
                            domain: [0, maxValue + 100 - (maxValue % 10)]
                        }
                    }
                />
            }
        </div>
    )
}

export default SalesVolumeChart