import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchList = createAsyncThunk("list/fetchList", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchAdditionalElements = createAsyncThunk('list/fetchAddionalElements', async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/${parameters}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchUpdates = createAsyncThunk('list/fetchUpdates', async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/${parameters}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const responseJSON = await response.json();
        const { data } = responseJSON;
        if (!Array.isArray(data)) {
            throw new Error('Data is not an array');
        }
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const listSlice = createSlice({
    name: "list",
    initialState: {
        items: [],
        folders: [],
        updates: {
            items: [],
            status: null,
            lastUpdateTime: null,
        },
        pageData: {},
        scrollTop: {
            page: null,
            scrollTop: 0,
        },
        selectLists: {},
        showedControl: '',
        search: '',
        sort: {},
        status: null,
        error: null,
        pagination: {
            pageNumber: 1,
            status: null,
        },
        neededToUpdate: false,
    },
    reducers: {
        setStatus: (state, { payload: status }) => {
            state.status = status;
        },
        setUpdateStatus: (state, { payload: status }) => {
            state.updates.status = status;
        },
        setUpdateItems: (state, { payload }) => {
            state.updates.items = payload;
        },
        setLastUpdateTime: (state, { payload }) => {
            state.updates.lastUpdateTime = payload;
        },
        setScrollTop: (state, { payload }) => {
            state.scrollTop = payload;
        },
        setShowedControl: (state, { payload }) => {
            state.showedControl = payload;
        },
        setSearch: (state, { payload }) => {
            state.search = payload;
        },
        setSort: (state, { payload }) => {
            state.sort = payload;
        },
        setNeededToUpdate: (state, { payload }) => {
            state.neededToUpdate = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchList.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            });
        builder
            .addCase(fetchList.fulfilled, (state, action) => {
                state.items = action.payload.data;
                state.pageData = action.payload.page;
                state.pagination = { ...state.pagination, ...action.payload.pagination };
                state.error = null;
                state.status = "success";
            });
        builder
            .addCase(fetchList.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
        builder
            .addCase(fetchAdditionalElements.pending, (state, action) => {
                state.pagination.status = "loading";
            });
        builder
            .addCase(fetchAdditionalElements.fulfilled, (state, action) => {
                state.items = [...state.items, ...action.payload.data];
                state.pagination = { ...state.pagination, ...action.payload.pagination };
                state.pagination.status = "success";
            });
        builder
            .addCase(fetchAdditionalElements.rejected, (state, action) => {
                state.pagination.status = "error";
            });
        builder
            .addCase(fetchUpdates.pending, (state, action) => {
                state.updates.status = "loading";
            });
        builder
            .addCase(fetchUpdates.fulfilled, (state, action) => {
                state.updates.items = action.payload;
                state.updates.status = "success";
            });
        builder
            .addCase(fetchUpdates.rejected, (state, action) => {
                state.updates.status = "error";
            });
    },
});

export default listSlice.reducer;
export const { setNeededToUpdate, setStatus, setUpdateStatus, setLastUpdateTime, setScrollTop, setShowedControl, setSearch, setSort, setUpdateItems } = listSlice.actions;