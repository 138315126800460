import React from 'react';
import './Loader.css';

function Loader({ className, style, loader }) {
    return (
        <div className={'flex-center align-center flex-column ' + (className ?? '')} style={style ?? {}}>
            {
                loader === undefined &&
                <div>
                    <div className="loader">
                        <div className="loader__bar"></div>
                        <div className="loader__bar"></div>
                        <div className="loader__bar"></div>
                        <div className="loader__bar"></div>
                        <div className="loader__bar"></div>
                        <div className="loader__ball"></div>
                    </div>
                    <p className='description'>загрузка...</p>
                </div>
            }
            {
                loader === 2 &&
                <div className="loader-2"></div>
            }
        </div>

    )
}

export default Loader