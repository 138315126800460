import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchElement = createAsyncThunk("element/fetchElement", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchDeleteElement = createAsyncThunk("element/fetchDeleteElement", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/${parameters}`, {
            method: "DELETE",
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchSubmitElement = createAsyncThunk("element/fetchSubmitElement", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/${parameters.path}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(parameters.body),
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchUploadFile = createAsyncThunk("element/fetchUploadFile", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/upload/file/${parameters.token}`, {
            method: "POST",
            body: parameters.body,
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const actionLabel = (action, pathnamePartsLength = 3) => {
    let actionLabel = '';
    switch (action !== 'add' ? action : pathnamePartsLength === 3 ? 'add' : 'copy') {
        case 'add': actionLabel = 'Добавление'; break;
        case 'copy': actionLabel = 'Копирование'; break;
        case 'edit': actionLabel = 'Редактирование'; break;
        default: break;
    }
    return actionLabel;
};

const elementSlice = createSlice({
    name: "element",
    initialState: {
        element: {},
        page: {},
        forwardedFromElementWindow: false,
        isComplated: true,
        uploads: {},
        uploadStatus: null,
        uploadResult: null,
        status: null,
        error: null,
    },
    reducers: {
        setElementStatus(state, action) {
            state.status = action.payload;
        },
        setElement(state, action) {
            const { property, value } = action.payload;
            state.element[property] = value;
        },
        setIsComplated(state, action) {
            state.isComplated = action.payload;
        },
        setForwardedFromElementWindow(state, action) {
            state.forwardedFromElementWindow = action.payload;
        },
        setUploadStatus(state, action) {
            state.uploadStatus = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchElement.pending, (state, action) => {
                state.status = "loading";
                state.uploadResult = null;
                state.uploadStatus = null;
                state.isComplated = true;
                state.error = null;
            });
        builder
            .addCase(fetchElement.fulfilled, (state, action) => {
                state.element = Array.isArray(action.payload.data) ? {} : action.payload.data;
                state.page = action.payload.page;
                state.error = null;
                state.status = "success";
            });
        builder
            .addCase(fetchElement.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
        builder
            .addCase(fetchDeleteElement.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            });
        builder
            .addCase(fetchDeleteElement.fulfilled, (state, action) => {
                state.element = action.payload.data;
                state.page = action.payload.page;
                state.error = null;
                state.status = "deleted";
            });
        builder
            .addCase(fetchDeleteElement.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
        builder
            .addCase(fetchSubmitElement.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            });
        builder
            .addCase(fetchSubmitElement.fulfilled, (state, action) => {
                state.element = action.payload.data;
                state.page = action.payload.page;
                state.error = null;
                state.status = "submitted";
            });
        builder
            .addCase(fetchSubmitElement.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
        builder
            .addCase(fetchUploadFile.pending, (state, action) => {
                const property = action.meta.arg.property;
                state.uploads[property] = { status: "loading", result: null, error: null };
            });
        builder
            .addCase(fetchUploadFile.fulfilled, (state, action) => {
                const property = action.meta.arg.property;
                state.uploads[property] = { status: "success", result: action.payload, error: null };
            });
        builder
            .addCase(fetchUploadFile.rejected, (state, action) => {
                const property = action.meta.arg.property;
                state.uploads[property] = { status: "error", result: null, error: action.payload };
            });
    },
});


export default elementSlice.reducer;
export const { setUploadStatus, setElementStatus, setElement, setIsComplated, setForwardedFromElementWindow } = elementSlice.actions;


export const calculation = (params) => {
    const args = parseInput(params.input);
    const data = { ...params.data, ...params.parentElement };
    if (typeof functions[args.functionName] === 'function') {
        return functions[args.functionName](args.args, data);
    }
    return;
}

// {
//     "type": "function",
//     "functionName": "sumOfList",
//     "args": [
//       {
//         "type": "function",
//         "functionName": "multiply",
//         "args": [
//           {
//             "type": "property",
//             "property": "quantity"
//           },
//           {
//             "type": "property",
//             "property": "coefficient"
//           }
//         ]
//       },
//       {
//         "type": "property",
//         "property": "goods"
//       }
//     ]
//   }

const functions = {
    'sumOfList': (args, data) => {
        const list = data[args[1].property];
        try {
            return list.reduce((acc, item) => {
                if (args[0].type === 'function') {
                    return acc + functions[args[0].functionName](args[0].args, item);
                }
                if (args[0].type === 'property') {
                    return acc + item[args[0].property];
                }
                if (args[0].type === 'static') {
                    return acc + args[0].value;
                }
                return acc;
            }, 0);
        } catch {
            throw new Error('Error in sumOfList function');
        }
    },
    'multiply': (args, data) => {
        try {
            return data[args[0].property] * data[args[1].property];
        } catch {
            throw new Error('Error in multiply function');
        }
    },
    'divide': (args, data) => {
        try {
            return data[args[0].property] / data[args[1].property];
        } catch {
            throw new Error('Error in divide function');
        }
    },
    'minus': (args, data) => {
        try {
            return data[args[0].property] - data[args[1].property];
        } catch {
            throw new Error('Error in minus function');
        }
    },
    'plus': (args, data) => {
        try {
            return data[args[0].property] + data[args[1].property];
        } catch {
            throw new Error('Error in plus function');
        }
    },
};

//sumOfList(round(goods.quantity * goods.coefficient, 3))

function parseInput(input) {
    const regex = /(\w+)\((.*)\)/;
    const match = input.match(regex);

    if (!match) return parseSingleArg(input);

    const functionName = match[1];
    const argsString = match[2];

    const args = parseArgs(argsString);

    return {
        type: 'function',
        functionName: functionName,
        args: args
    };
}

function parseArgs(argsString) {
    const args = [];
    let currentArg = '';
    let depth = 0;

    for (let char of argsString) {
        if (char === '(') {
            depth++;
        } else if (char === ')') {
            depth--;
        }

        if (char === ',' && depth === 0) {
            args.push(currentArg.trim());
            currentArg = '';
        } else {
            currentArg += char;
        }
    }

    if (currentArg) {
        args.push(currentArg.trim());
    }

    return args.map(arg => parseSingleArg(arg));
}

function parseSingleArg(arg) {
    const functionRegex = /(\w+)\((.*)\)/;
    const match = arg.match(functionRegex);

    if (match) {
        const functionName = match[1];
        const argsString = match[2];
        const args = parseArgs(argsString);

        return {
            type: 'function',
            functionName: functionName,
            args: args
        };
    }

    const mathOperations = [
        {
            title: 'plus',
            sign: '+'
        },
        {
            title: 'minus',
            sign: '-'
        },
        {
            title: 'divide',
            sign: '/'
        },
        {
            title: 'multiply',
            sign: '*'
        }
    ];

    for (const operation of mathOperations) {
        const { title, sign } = operation;
        if (arg.includes(sign)) {
            const properties = arg.split(sign).map(prop => ({
                type: 'property',
                property: prop.trim()
            }));
            return {
                type: 'function',
                functionName: title,
                args: properties,
            };
        }
    }

    if (!isNaN(arg)) {
        return {
            type: 'static',
            value: Number(arg)
        };
    }

    return {
        type: 'property',
        property: arg.trim()
    };
}
