import React, { useEffect, useState } from 'react';
import Chart from '../Chart/Chart';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSales, setActiveFilterIndexSales } from './widgets.slice';
import { splitValue } from '../Number/Number';

function SalesChart() {
    const filters = [
        {
            label: 'Сегодня',
            maxValue: 'today',
        },
        {
            label: 'Неделя',
            maxValue: 'thisWeek',
        },
        {
            label: 'Месяц',
            maxValue: 'thisMonth',
        },
        {
            label: 'Год',
            maxValue: 'thisYear',
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        const token = localStorage.getItem('token');
        const shop_id = localStorage.getItem('shop_id');
        dispatch(fetchSales(token + '?shop_id=' + shop_id + '&pagination=false'));
    }, [dispatch]);

    const { activeFilterIndex, status, items } = useSelector(state => state.widgets.salesChart);

    const [itemsState, setItemsState] = useState([]);
    const [filteredData, setFilteredData] = useState();
    useEffect(() => {
        if (status === 'success') {
            setFilteredData(groupItemsByDateWithSums(items));
            dispatch(setActiveFilterIndexSales(0));
        }
    }, [status, items]);

    const groupItemsByDateWithSums = (items) => {
        const today = new Date();
        const grouped = {
            today: [],
            thisWeek: [],
            thisMonth: [],
            thisYear: []
        };

        // Russian names for days of the week and months
        const daysOfWeek = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];
        const monthsOfYear = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

        // Helper function to format hour
        const formatHour = (date) => {
            const hours = date.getHours().toString().padStart(2, '0');
            return `${hours}:00`; // Format as HH:00
        };

        items.forEach(item => {
            const date = new Date(item.date);
            const hourKey = formatHour(date);
            const dayKey = daysOfWeek[date.getDay()]; // Get Russian day name
            const monthKey = monthsOfYear[date.getMonth()]; // Get Russian month name

            // Group by today (by hours)
            if (date.toDateString() === today.toDateString()) {
                const existingHour = grouped.today.find(entry => entry.time === hourKey);
                if (existingHour) {
                    existingHour.Сумма += item.sum; // Sum the value
                } else {
                    grouped.today.push({ time: hourKey, Сумма: item.sum }); // Initialize new entry
                }
            }

            // Group by this week (by days)
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay()); // Monday as the start of the week
            if (date >= startOfWeek) {
                const existingDay = grouped.thisWeek.find(entry => entry.time === dayKey);
                if (existingDay) {
                    existingDay.Сумма += item.sum; // Sum the value
                } else {
                    grouped.thisWeek.push({ time: dayKey, Сумма: item.sum }); // Initialize new entry
                }
            }

            // Group by this month (by days)
            const dateKey = date.toLocaleDateString(); // Use local date format for days
            if (date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
                const existingDay = grouped.thisMonth.find(entry => entry.time === dateKey);
                if (existingDay) {
                    existingDay.Сумма += item.sum; // Sum the value
                } else {
                    grouped.thisMonth.push({ time: dateKey, Сумма: item.sum }); // Initialize new entry
                }
            }

            // Group by this year (by months)
            if (date.getFullYear() === today.getFullYear()) {
                const existingMonth = grouped.thisYear.find(entry => entry.time === monthKey);
                if (existingMonth) {
                    existingMonth.Сумма += item.sum; // Sum the value
                } else {
                    grouped.thisYear.push({ time: monthKey, Сумма: item.sum }); // Initialize new entry
                }
            }
        });

        // Sort today by hour
        grouped.today.reverse();

        // Sort thisWeek by actual date
        grouped.thisWeek.reverse();

        // Sort thisMonth by actual date
        grouped.thisMonth.reverse();

        // Sort thisYear by actual month
        grouped.thisYear.reverse();

        return grouped;
    };


    useEffect(() => {
        if (status === 'success' && filteredData !== undefined) {
            setItemsState(filteredData[filters[activeFilterIndex].maxValue]);
        }
    }, [activeFilterIndex, filteredData]);

    function CustomTooltip({ payload, label, active }) {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', color: '#0076FE', border: '1px solid var(--primary-border-color)', padding: '5px' }}>
                    <p style={{ padding: 0, margin: 0, marginBottom: '5px' }}>{payload[0]?.payload?.time}</p>
                    <p style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>{splitValue(payload[0]?.value) + ' ₽'}</p>
                </div>
            );
        }

        return null;
    }
    return (
        <div>
            <div className="header-filters flex-start align-center medium-margin-top overflow-x-auto">
                {
                    filters.map((filter, index) => (
                        <button
                            className={"button header-filter-button small-font-size small-margin color-primary no-wrap-white-space" + (activeFilterIndex === index ? ' active-filter' : '')}
                            key={index}
                            onClick={() => { dispatch(setActiveFilterIndexSales(index)) }}>
                            {filter.label}
                        </button>
                    ))
                }
            </div>
            <Chart
                data={itemsState}
                props={
                    {
                        lineDataKey: "Сумма",
                        xAxisDataKey: "time",
                        CustomTooltip: CustomTooltip
                    }
                }
            />
        </div>
    )
}

export default SalesChart