import React from 'react';
import Chart from '../Chart/Chart';
import { splitValue } from '../Number/Number';

function PricesChangeChart({ props }) {
    function createChartData(product) {
        const { operations, baseUnit, weightSaveUnit, saveUnit } = product;
        const result = [];

        if (operations === undefined) {
            return [];
        }
        
        operations.forEach(operation => {
            if (operation.operationType.text === "Реализация") {
                let price = operation.price;
                let unit = operation.unit;
                // Check if the operation unit matches the base unit
                if (operation.unit === baseUnit && baseUnit === 'кг') {
                    // Convert the price to saveUnit price
                    price = price * weightSaveUnit;
                    unit = saveUnit;
                }
                // Push the formatted object to the result array
                result.push({
                    price: price,
                    date: new Date(operation.date).toLocaleDateString(), // Format the date
                    dateForSorting: new Date(operation.date),
                    unit: unit,
                });
            }
        });

        return result.sort((a, b) => a.dateForSorting - b.dateForSorting);
    }

    function CustomTooltip({ payload, label, active }) {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', color: '#0076FE', border: '1px solid var(--primary-border-color)', padding: '5px' }}>
                    <p style={{ padding: 0, margin: 0, marginBottom: '5px' }}>{'Цена продажи'}</p>
                    <p style={{ padding: 0, margin: 0, marginBottom: '5px' }}>{payload[0]?.payload?.date}</p>
                    <p style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>{splitValue(payload[0]?.value) + ' ₽ ' + payload[0]?.payload?.unit}</p>
                </div>
            );
        }

        return null;
    }
    const prices = createChartData(props);
    return (
        <div className='collection'>
            <p className="label-view">{props.label}</p>

            <Chart
                data={prices}
                props={
                    {
                        lineDataKey: "price",
                        xAxisDataKey: "date",
                        CustomTooltip: CustomTooltip,
                        domain: [Math.min(...prices.map(price => price.price)) - 100, Math.max(...prices.map(price => price.price)) + 100]
                    }
                }
            />
        </div>
    )
}

export default PricesChangeChart