import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '../Dialog/Dialog';

function BlockedNavLink({ to, ...props }) {
    const navigate = useNavigate();

    const handleClick = (e) => {
        if (e.target.classList.contains('dialog-button')) {
            return;
        }
        const blocked = location.search.includes('notSaved=true') === true && location.pathname.split('/')[1] !== 'view';
        if (blocked) {
            setNotSaveIsOpen(true);
        } else {
            navigate(to);
        }
    };
    const location = window.location;
    const iconRef = useRef(null);

    const [notSaveIsOpen, setNotSaveIsOpen] = useState(false);
    useEffect(() => {
        if (location.pathname.split('/')[1] === to.split('/')[1]) {
            iconRef.current && iconRef.current.classList.add('active');
        } else {
            iconRef.current && iconRef.current.classList.remove('active');
        }
    }, [location.pathname]);
    return (
        <div {...props} ref={iconRef} onClick={handleClick}>
            {props.children}

            <Dialog
                isOpen={notSaveIsOpen}
                title="Данные не сохранились"
                text='Вы дейстивительно хотите покинуть страницу?'
                onCancel={
                    () => {
                        setNotSaveIsOpen(false);
                        if (location.search.includes('notSaved') === false) {
                            navigate('?notSaved=true');
                        }
                    }
                }
                onOk={
                    () => {
                        setNotSaveIsOpen(false);
                        navigate(to);
                    }
                }
            />
        </div>
    )
};

export default BlockedNavLink