import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { actionLabel, calculation, fetchDeleteElement, fetchElement, setElement, setElementStatus, setForwardedFromElementWindow } from './element.slice';
import Component from '../Component/Component';
import './Element.css';
import menuIcon from '../../images/menu-vertical.png';
import Context from '../Context/Context';
import Dialog from '../Dialog/Dialog';
import leftIcon from '../../images/left-bold.png';
import ImageComponent from '../ImageComponent/ImageComponent';
import { setNeededToUpdate } from '../List/list.slice';

function Element({ action }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const pathnameParts = location.pathname.split('/');
    const pathnamePartsLength = pathnameParts.length;
    const actions = ['view', 'edit', 'add', 'copy'];
    const pageCollection = pathnameParts[(actions.includes(pathnameParts[1]) ? 2 : 1)];

    const { element, page, status, isComplated, forwardedFromElementWindow } = useSelector(state => state.element);
    const { allPages: pages } = useSelector(state => state.pages);
    const pageData = pages.find(pageFind => pageFind.collection === pageCollection);
    const elementPage = pageData?.type?.value === 'element';
    const { _id, ...addElement } = (element ?? {});

    const id = elementPage !== true ? pathnamePartsLength === 3 ? undefined : pathnameParts.pop() : undefined;
    const item = elementPage !== true ? 'item=' + id : '';

    const [elementState, setElementState] = useState(action === 'add' ? {} : undefined);

    useEffect(() => {
        const location = window.location;
        if (JSON.stringify(elementState) !== JSON.stringify(element) && action !== 'view') {
            if (location.search.includes('notSaved') === false) {
                if (element?._id === elementState?._id) {
                    navigate(location.search.includes('?') ? location.search + '&notSaved=true' : '?notSaved=true');
                } else {
                    setElementState();
                }
            }
        }

        const beforeUnload = (e) => {
            if (JSON.stringify(elementState) === JSON.stringify(element)) {
                return;
            }
            e.preventDefault();
        }

        action !== 'view' && window.addEventListener('popstate', goBack);
        action !== 'view' && window.addEventListener('beforeunload', beforeUnload);
        return () => {
            action !== 'view' && window.removeEventListener('popstate', goBack);
            action !== 'view' && window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [element, elementState, action, forwardedFromElementWindow]);

    const goBack = () => {
        if (forwardedFromElementWindow === true) {
            dispatch(setForwardedFromElementWindow(false));
            return;
        }

        setNotSaveIsOpen(true);
    }

    useEffect(() => {
        const hasQuery = pathnamePartsLength !== 3 || pageData?.isShopOrientedPage === true;
        if (pageData === undefined) {
            return;
        }
        dispatch(fetchElement(pageCollection + '/' + localStorage.getItem('token') + (hasQuery ? '?' : '') + (pathnamePartsLength === 3 ? '' : item) + (pageData?.isShopOrientedPage === true ? (pathnamePartsLength === 3 ? '' : '&') + 'shop_id=' + localStorage.getItem('shop_id') : '')));
    }, [dispatch, pageCollection, action, pathnamePartsLength, pageData?.isShopOrientedPage, pages]);

    useEffect(() => {
        if (status === 'deleted') {
            navigate(-1);
        }
        if (status === 'submitted') {
            dispatch(setElementStatus('success'));
            dispatch(setNeededToUpdate(true));
            if (location.search.includes('notSaved') === true) {
                navigate(-2);
                return;
            }
            navigate(-1);
        }
    }, [pageCollection, status, navigate]);

    const [contextStyles, setContextStyles] = useState({
        display: 'none',
    });

    const handleOutsideClick = e => {
        if (e.target?.parentElement?.classList !== undefined && Array.from(e.target?.parentElement?.classList).includes('context-menu')) {
            setTimeout(() => {
                setContextStyles({ display: 'none' });
            }, 200);
            return;
        }
        setContextStyles({ display: 'none' });
    }
    const handleKeyDown = e => {
        if (e.key === 'Escape') {
            document.getElementsByClassName('cancel')[0] && document.getElementsByClassName('cancel')[0].click();
        }

        if (e.target === document.activeElement && document.activeElement.tagName === 'INPUT') {
            return;
        }
        if (e.target === document.activeElement && document.activeElement.tagName === 'TEXTAREA') {
            return;
        }

        if (e.key === 'Enter' && e.ctrlKey) {
            if (action === 'view') {
                navigate('/' + pageCollection);
                return;
            }
            document.getElementsByClassName('accept')[0] && document.getElementsByClassName('accept')[0].click();
        }
        if (e.key === 'F2') {
            if (action === 'view' && element?.canEdit === true) {
                const _id = element?._id;
                navigate('/edit/' + pageCollection + '/' + _id?.toString());
            }
        }
        if (e.key === 'Delete' || e.key === '-') {
            if (element?.canDelete === true) {
                setOpen(true);
            }
        }
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            if (!isOpen) {
                return;
            }
            if (Array.from(document.getElementsByClassName('dialog-button')).indexOf(document.activeElement) === -1) {
                document.getElementsByClassName('dialog-button')[0].focus();
                return;
            }
            if (document.getElementsByClassName('dialog-button')[0] === document.activeElement) {
                document.getElementsByClassName('dialog-button')[1].focus();
                return;
            }
            document.getElementsByClassName('dialog-button')[0].focus();
        }
    }
    useEffect(() => {
        if (status === 'success') {
            if (action === 'add') {
                const lid = new URLSearchParams(location.search).get('lid');
                if (lid !== null && element.folder === undefined) {
                    dispatch(setElement({ property: 'folder', value: { _id: lid } }));
                }
            }

            setElementState(element);

            document.addEventListener('click', handleOutsideClick);
            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('click', handleOutsideClick);
                document.removeEventListener('keydown', handleKeyDown);
            }
        }
    }, [status]);

    const handleMenuClick = e => {
        setTimeout(() => {
            setContextStyles({
                display: 'block',
                position: 'absolute',
                top: '5px',
                right: '40px',
            });
        }, 100);
    };

    let actionLabelResult = actionLabel(action, pathnamePartsLength);


    useEffect(() => {
        const firstPart = actionLabelResult === '' ? '' : actionLabelResult;
        const listTitle = typeof element?.[page?.listTitle] === 'object' ? page?.listTitle?.text : page?.listTitle;
        const secondPart = element?.[listTitle] === undefined ? page?.title ?? '' : element?.[listTitle];
        document.title = firstPart.toString() + (firstPart !== '' && secondPart !== '' ? ' - ' : '') + secondPart.toString();
    }, [actionLabelResult, element, page?.listTitle, page?.title]);

    const [isOpen, setOpen] = useState(false);
    const contextActions = [{ label: 'Скопировать', disabled: !page?.accesses?.canAdd, action: 'copy' }, { label: 'Редактировать', disabled: !(page?.accesses?.canEdit && element?.canEdit !== false), action: 'edit' }, { label: 'Удалить', disabled: !(page?.accesses?.canDelete && element?.canDelete !== false), action: 'delete' }];

    const [notSaveIsOpen, setNotSaveIsOpen] = useState(false);

    return (
        <div>
            <div className="element-container left-animated">
                {
                    status === 'success' &&
                    <div className="components">
                        <div className="element-header bottom-border full-width">
                            <div className='align-center flex-start' style={{ width: 'calc(100% - 30px)' }}>
                                <div className='small-margin-top small-right-margin align-center flex-center' onClick={e => { location.search === '' ? navigate(-1) : goBack(e) }}>
                                    <ImageComponent src={leftIcon} width={'30px'} />
                                </div>
                                <h3 className="element-title small-left-margin">{(actionLabelResult === '' ? '' : actionLabelResult + ' - ') + (element?.[page?.listTitle] === undefined ? page?.title : typeof element?.[page.listTitle] === 'object' ? element?.[page.listTitle]?.text : element?.[page.listTitle])}</h3>
                            </div>
                            {
                                (page?.accesses?.canAdd === true || page?.accesses?.canEdit === true || page?.accesses?.canDelete === true) &&
                                <div className='menu-vertical-container'>
                                    <button className="button menu-vertical" onClick={handleMenuClick} style={{ display: action === 'view' ? 'flex' : 'none' }}>
                                        <img src={menuIcon} alt="" height={20} />
                                    </button>
                                    <Context setOpen={setOpen} target={page.collection + '/' + (_id?.toString() ?? '')} style={contextStyles} elements={contextActions} />
                                </div>
                            }
                        </div>
                        {
                            Object.entries(page?.components ?? {})?.map(([component, value], index) => {
                                try {
                                    if (value.filterItem !== undefined && !value.filterValues.includes((element[value.filterItem]?.value ?? element[value.filterItem]))) {
                                        let notFound = true;
                                        value.filterValues.forEach(filterValue => {
                                            if (filterValue.includes('*')) {
                                                if (element[value.filterItem]?.value?.includes(filterValue.replace('*', ''))) {
                                                    notFound = false;
                                                }
                                            }
                                        });
                                        if (notFound) {
                                            return null;                                            
                                        }
                                    }
                                    const returnValue = { ...value };
                                    if (value.unit !== undefined) {
                                        if (value.unit.type === 'link') {
                                            if (element[value.unit.property] !== undefined) {
                                                returnValue.unit = element[value.unit.property];
                                            } else {
                                                delete returnValue.unit;
                                            }
                                        } else if (value.unit.type === 'static') {
                                            returnValue.unit = value.unit.value;
                                        }
                                    }
                                    const returnData = (value.calculation !== undefined && action === 'view') ? calculation({ input: value.calculation, data: element }) : element[component];
                                    const returnAction = value.calculation !== undefined ? 'view' : action;
                                    return (
                                        <div key={index} className="component">
                                            <Component property={component} props={returnValue} parentElement={element} data={returnData} action={returnAction !== 'add' ? returnAction : pathnamePartsLength === 3 ? 'add' : 'copy'} />
                                        </div>
                                    )
                                } catch (error) {
                                    console.log(error);
                                    return null;
                                }
                            })
                        }
                        {
                            ((action === 'add' || action === 'copy' || action === 'edit') && !isComplated) &&
                            <p className="error loading">Некоторые обязательные поля (*) не заполнены, пожалуйста заполните их и попробуйте ещё раз</p>
                        }
                        {
                            (action === 'add' || action === 'copy') &&
                            <div className="component flex-center">
                                <Component props={{ type: 'button', label: 'Отмена' }} action={'cancel'} />
                                <Component props={{ type: 'button', label: 'Добавить', path: page.collection + '/' + localStorage.getItem('token') + (page?.isShopOrientedPage === true ? '?shop_id=' + localStorage.getItem('shop_id') : ''), body: addElement }} action={'submit'} />
                            </div>
                        }
                        {
                            (action === 'edit') &&
                            <div className="component flex-center">
                                <Component props={{ type: 'button', label: 'Отмена' }} action={'cancel'} />
                                <Component props={{ type: 'button', label: 'Сохранить', path: page.collection + '/' + localStorage.getItem('token') + (page?.isShopOrientedPage === true ? '?shop_id=' + localStorage.getItem('shop_id') : ''), body: element }} action={'submit'} />
                            </div>
                        }

                        <Dialog
                            isOpen={isOpen}
                            title="Удаление"
                            text='Вы дейстивительно хотите удалить этот элемент?'
                            action='delete'
                            onCancel={
                                () => {
                                    setOpen(false);
                                }
                            }
                            onOk={
                                () => {
                                    setOpen(false);
                                    dispatch(fetchDeleteElement(page.collection + '/' + localStorage.getItem('token') + ((pathnamePartsLength !== 3 || page?.isShopOrientedPage === true) ? '?' : '') + (pathnamePartsLength === 3 ? '' : item) + (page?.isShopOrientedPage === true ? (pathnamePartsLength === 3 ? '' : '&') + 'shop_id=' + localStorage.getItem('shop_id') : '')));
                                }
                            }
                        />

                        <Dialog
                            isOpen={notSaveIsOpen}
                            title="Данные не сохранились"
                            text='Вы дейстивительно хотите выйти?'
                            onCancel={
                                () => {
                                    setNotSaveIsOpen(false);
                                    if (JSON.stringify(elementState) !== JSON.stringify(element) && action !== 'view') {
                                        if (location.search.includes('notSaved') === false) {
                                            navigate('?notSaved=true');
                                        }
                                    }
                                }
                            }
                            onOk={
                                () => {
                                    setNotSaveIsOpen(false);
                                    if (location.search.includes('notSaved') === true) {
                                        navigate(-2);
                                        return;
                                    }
                                    navigate(-1);
                                }
                            }
                        />
                    </div>
                }

                {
                    status === 'loading' &&
                    <div className="components">
                        <div className="element-header">
                            <div className="element-title loading" style={{ height: '35px', width: '100%', display: 'flex', alignItems: 'center' }}><p style={{ height: '20px', width: '50%', margin: '5px' }} className='loading'></p></div>
                        </div>
                        {
                            Array.from({ length: 10 }).map((el, index) => {
                                return (
                                    <div key={index} style={{ height: Math.round(Math.random() * 100 + 20) + 'px', width: '100%', minWidth: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='loading'>
                                        <div className='loading' style={{ width: '90%', height: '80%', borderRadius: '20px' }}></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {
                    status === 'error' &&
                    <div className='error'>Не удалось загрузить страницу</div>
                }
            </div>
        </div>
    )
}

export default Element