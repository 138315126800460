import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchWidgetsList = createAsyncThunk("widgets/fetchWidgetsList", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/widgets/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchLowVolumeGoods = createAsyncThunk("widgets/fetchLowVolumeGoods", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/goods/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchServerLoad = createAsyncThunk("widgets/fetchServerLoad", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/requestsLogs/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchServerLoadChart = createAsyncThunk("widgets/fetchServelLoadChart", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/requestsLogs/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchSales = createAsyncThunk("widgets/fetchSales", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/realizationGoods/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchSalesVolume = createAsyncThunk("widgets/fetchSalesVolume", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/realizationGoods/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchIncomes = createAsyncThunk("widgets/fetchIncomes", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/incomingCashs/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchOutcomes = createAsyncThunk("widgets/fetchOutcomes", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/outcomingCashs/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchIncomingGoods = createAsyncThunk("widgets/fetchIncomingGoods", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/incomingGoods/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const widgetsSlice = createSlice({
    name: "widgets",
    initialState: {
        lowVolumeGoodsWidget: {
            status: null,
            error: null,
            items: [],
            activeFilterIndex: 0,
            expanded: undefined,
        },
        serverLoad: {
            status: null,
            error: null,
            items: [],
            activeFilterIndex: 0,
        },
        serverLoadChart: {
            status: null,
            error: null,
            items: [],
            activeFilterIndex: 0,
        },
        salesChart: {
            status: null,
            error: null,
            items: [],
            activeFilterIndex: null,
        },
        salesVolumeChart: {
            status: null,
            error: null,
            items: [],
        },
        incomes: {
            status: null,
            error: null,
            items: [],
        },
        outcomes: {
            status: null,
            error: null,
            items: [],
        },
        incomingGoods: {
            status: null,
            error: null,
            items: [],
        },
        widgets: [],
        widgetsShopId: null,
        status: null,
        error: null,
    },
    reducers: {
        setActiveFilterIndex: (state, action) => {
            state.lowVolumeGoodsWidget.activeFilterIndex = action.payload;
        },
        setActiveFilterIndexServerLoad: (state, action) => {
            state.serverLoad.activeFilterIndex = action.payload;
        },
        setActiveFilterIndexSales: (state, action) => {
            state.salesChart.activeFilterIndex = action.payload;
        },
        setAFIServerLoadChart: (state, action) => {
            state.serverLoadChart.activeFilterIndex = action.payload;
        },

        setExpanded: (state, action) => {
            state.lowVolumeGoodsWidget.expanded = action.payload;
        },

        setWidgetStatus: (state, action) => {
            state.status = action.payload;
            if (action.payload === null) {
                state.widgets = [];
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWidgetsList.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            });

        builder
            .addCase(fetchWidgetsList.fulfilled, (state, action) => {
                state.widgets = action.payload.data;
                state.widgetsShopId = localStorage.getItem("shop_id");
                state.status = "success";
                state.error = null;
            });

        builder
            .addCase(fetchWidgetsList.rejected, (state, action) => {
                state.status = "error";
                state.error = action.payload;
            });
        builder
            .addCase(fetchLowVolumeGoods.pending, (state, action) => {
                state.lowVolumeGoodsWidget.status = "loading";
                state.lowVolumeGoodsWidget.error = null;
            });

        builder
            .addCase(fetchLowVolumeGoods.fulfilled, (state, action) => {
                state.lowVolumeGoodsWidget.items = action.payload.data;
                state.lowVolumeGoodsWidget.status = "success";
                state.lowVolumeGoodsWidget.error = null;
            });

        builder
            .addCase(fetchLowVolumeGoods.rejected, (state, action) => {
                state.lowVolumeGoodsWidget.status = "error";
                state.lowVolumeGoodsWidget.error = action.payload;
            });

        builder
            .addCase(fetchServerLoad.pending, (state, action) => {
                state.serverLoad.status = "loading";
                state.serverLoad.error = null;
            });

        builder
            .addCase(fetchServerLoad.fulfilled, (state, action) => {
                state.serverLoad.items = action.payload;
                state.serverLoad.status = "success";
                state.serverLoad.error = null;
            });

        builder
            .addCase(fetchServerLoad.rejected, (state, action) => {
                state.serverLoad.status = "error";
                state.serverLoad.error = action.payload;
            });

        builder
            .addCase(fetchSales.pending, (state, action) => {
                state.salesChart.status = "loading";
                state.salesChart.error = null;
            });

        builder
            .addCase(fetchSales.fulfilled, (state, action) => {
                state.salesChart.items = action.payload.data;
                state.salesChart.status = "success";
                state.salesChart.error = null;
            });

        builder
            .addCase(fetchSales.rejected, (state, action) => {
                state.salesChart.status = "error";
                state.salesChart.error = action.payload;
            });

        builder
            .addCase(fetchSalesVolume.pending, (state, action) => {
                state.salesVolumeChart.status = "loading";
                state.salesVolumeChart.error = null;
            });

        builder
            .addCase(fetchSalesVolume.fulfilled, (state, action) => {
                state.salesVolumeChart.items = action.payload.data;
                state.salesVolumeChart.status = "success";
                state.salesVolumeChart.error = null;
            });

        builder
            .addCase(fetchSalesVolume.rejected, (state, action) => {
                state.salesVolumeChart.status = "error";
                state.salesVolumeChart.error = action.payload;
            });

        builder
            .addCase(fetchServerLoadChart.pending, (state, action) => {
                state.serverLoadChart.status = "loading";
                state.serverLoadChart.error = null;
            });

        builder
            .addCase(fetchServerLoadChart.fulfilled, (state, action) => {
                state.serverLoadChart.items = action.payload;
                state.serverLoadChart.status = "success";
                state.serverLoadChart.error = null;
            });

        builder
            .addCase(fetchServerLoadChart.rejected, (state, action) => {
                state.serverLoadChart.status = "error";
                state.serverLoadChart.error = action.payload;
            });
        builder
            .addCase(fetchIncomes.pending, (state, action) => {
                state.incomes.status = "loading";
                state.incomes.error = null;
            });

        builder
            .addCase(fetchIncomes.fulfilled, (state, action) => {
                state.incomes.items = action.payload.data;
                state.incomes.status = "success";
                state.incomes.error = null;
            });

        builder
            .addCase(fetchIncomes.rejected, (state, action) => {
                state.incomes.status = "error";
                state.incomes.error = action.payload;
            });
        builder
            .addCase(fetchOutcomes.pending, (state, action) => {
                state.outcomes.status = "loading";
                state.outcomes.error = null;
            });

        builder
            .addCase(fetchOutcomes.fulfilled, (state, action) => {
                state.outcomes.items = action.payload.data;
                state.outcomes.status = "success";
                state.outcomes.error = null;
            });

        builder
            .addCase(fetchOutcomes.rejected, (state, action) => {
                state.outcomes.status = "error";
                state.outcomes.error = action.payload;
            });
        builder
            .addCase(fetchIncomingGoods.pending, (state, action) => {
                state.incomingGoods.status = "loading";
                state.incomingGoods.error = null;
            });

        builder
            .addCase(fetchIncomingGoods.fulfilled, (state, action) => {
                state.incomingGoods.items = action.payload.data;
                state.incomingGoods.status = "success";
                state.incomingGoods.error = null;
            });

        builder
            .addCase(fetchIncomingGoods.rejected, (state, action) => {
                state.incomingGoods.status = "error";
                state.incomingGoods.error = action.payload;
            });
    },
});

export default widgetsSlice.reducer;

export const { setWidgetStatus, setActiveFilterIndex, setActiveFilterIndexServerLoad, setActiveFilterIndexSales, setAFIServerLoadChart, setExpanded } = widgetsSlice.actions;