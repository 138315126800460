import React from 'react';
import './LongText.css';
import { setElement } from '../Element/element.slice';
import { useDispatch } from 'react-redux';


function LongText({ data, props, action, property, setElementState }) {
  const dispatch = useDispatch();
  return (
    <div>
      {
        action === 'view' &&
        <div className='text-view'>
          <p className="label-view">{props?.label}</p>
          <p className="secondary-text-view">{data !== undefined ? data.toString() : null}</p>
        </div>
      }
      {
        (action === 'add' || action === 'edit' || action === 'copy') &&
        <div className='text-view flex-column'>
          <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
          <textarea placeholder={props.placeholder ?? ''} value={data} onChange={e => setElementState ? setElementState(property, e.target.value) : dispatch(setElement({ property, value: e.target.value }))} />
        </div>
      }
    </div>
  )
}

export default LongText