import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchShops = createAsyncThunk("shop/fetchShops", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(host.url + '/api/seller/shops/' + parameters + '?justList=true&originPage=shop');
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchSendShop = createAsyncThunk("shop/fetchSendShop", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/shops/${parameters.token}?originPage=shop`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(parameters.element),
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchUploadBackground = createAsyncThunk("shop/fetchUploadBackground", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/upload/file/${parameters.token}`, {
            method: "POST",
            body: parameters.body,
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchUploadMain = createAsyncThunk("shop/fetchUploadMain", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/upload/file/${parameters.token}`, {
            method: "POST",
            body: parameters.body,
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const shopSlice = createSlice({
    name: "shop",
    initialState: {
        shop: undefined,
        shops: [],
        status: null,
        error: null,
        backgroundUploadStatus: null,
        backgroundUploadResult: null,
        mainUploadStatus: null,
        mainUploadResult: null,
    },
    reducers: {
        setUploadStatus(state, action) {
            if (action.payload.type === 'background') {
                state.backgroundUploadStatus = action.payload.status;
            }
            if (action.payload.type === 'main') {
                state.mainUploadStatus = action.payload.status;
            }
        },
        setShop(state, action) {
            if (action.payload === undefined) {
                state.shop = undefined;
                localStorage.removeItem('shop_id');
                return;
            }
            state.shop = action.payload;
            localStorage.setItem('shop_id', action.payload._id);
        },
        setShops(state, action) {
            state.shops = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchShops.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchShops.fulfilled, (state, action) => {
                state.shops = action.payload.data;
                state.error = null;
                state.status = "success";
            })
            .addCase(fetchShops.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            })
            .addCase(fetchUploadBackground.pending, (state, action) => {
                state.backgroundUploadStatus = "loading";
                state.backgroundUploadResult = null;
            })
            .addCase(fetchUploadBackground.fulfilled, (state, action) => {
                state.backgroundUploadResult = action.payload;
                state.backgroundUploadStatus = "success";
            })
            .addCase(fetchUploadBackground.rejected, (state, action) => {
                state.backgroundUploadResult = action.payload;
                state.backgroundUploadStatus = "error";
            })
            .addCase(fetchUploadMain.pending, (state, action) => {
                state.mainUploadStatus = "loading";
                state.mainUploadResult = null;
            })
            .addCase(fetchUploadMain.fulfilled, (state, action) => {
                state.mainUploadResult = action.payload;
                state.mainUploadStatus = "success";
            })
            .addCase(fetchUploadMain.rejected, (state, action) => {
                state.mainUploadResult = action.payload;
                state.mainUploadStatus = "error";
            })
            .addCase(fetchSendShop.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchSendShop.fulfilled, (state, action) => {
                state.shop = action.payload.data;
                state.error = null;
                state.status = "success";
            })
            .addCase(fetchSendShop.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
    }
});

export default shopSlice.reducer;

export const { setUploadStatus, setShop, setShops } = shopSlice.actions;