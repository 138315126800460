import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSendShop, fetchShops, fetchUploadBackground, fetchUploadMain, setShop, setShops, setUploadStatus } from './shop.slice';
import './Shop.css';
import ImageComponent from '../ImageComponent/ImageComponent';
import addIcon from '../../images/modal-plus.png';
import shopEmpty from '../../images/shop-gray.png';
import Component from '../Component/Component';
import background from '../../images/background.png';
import shopAvatar from '../../images/shop-avatar.png';
import editIcon from '../../images/edit-white.png';
import loading from '../../images/loading.gif';
import { host } from '../../App';
import { setStatus } from '../List/list.slice';


function Shop() {
    const dispatch = useDispatch();
    const { shop, shops, status } = useSelector((state) => state.shop);

    const { backgroundUploadStatus, backgroundUploadResult, mainUploadStatus, mainUploadResult } = useSelector((state) => state.shop);
    const token = localStorage.getItem('token');
    useEffect(() => {
        dispatch(fetchShops(token));
    }, [dispatch, token]);
    const [shopEdit, setShopEdit] = useState(shop);
    const [shopAction, setShopAction] = useState('view');

    const [isComplated, setIsComplated] = useState(true);
    const submit = () => {
        if (shopEdit.title !== undefined && shopEdit.title.trim() !== '') {
            dispatch(fetchSendShop({ token: localStorage.getItem('token'), element: shopEdit }));
            dispatch(setShops(shops.map(shop => shop._id === shopEdit._id ? shopEdit : shop)));
            dispatch(setShop(shopEdit));
            setShopAction('view');
            return;
        }

        setIsComplated(false);
        setTimeout(() => {
            setIsComplated(true);
        }, 5000);
    }

    const onCancel = () => {
        if (shopAction === 'add') {
            setShopEdit();
        }
        if (shopAction === 'edit') {
            setShopEdit(shop);
        }
        setShopAction('view');
    }

    useEffect(() => {
        if (status === 'success' && shops.find(shop => shop._id === localStorage.getItem('shop_id'))) {
            setShopEdit(shops.find(shop => shop._id === localStorage.getItem('shop_id')));
            setShopAction('view');
        }
    }, [status, shops]);

    const inputRef = useRef(null);
    const [targetImage, setTargetImage] = useState();
    const fileSelected = (e) => {
        try {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                const formData = new FormData();
                formData.append('file', file);
                if (inputRef?.current !== undefined && inputRef?.current !== null) {
                    if (targetImage === 'background') {
                        setShopEdit({ ...shopEdit, images: shopEdit.images ? shopEdit.images.find(image => image.type === 'background') ? shopEdit.images.map(image => image.type === 'background' ? { ...image, src: reader.result } : image) : [...shopEdit.images, { type: 'background', src: reader.result }] : [{ type: 'background', src: reader.result }] });
                        dispatch(fetchUploadBackground({ token: localStorage.getItem('token'), body: formData }));
                    } else if (targetImage === 'main') {
                        setShopEdit({ ...shopEdit, images: shopEdit.images ? shopEdit.images.find(image => image.type === 'main') ? shopEdit.images.map(image => image.type === 'main' ? { ...image, src: reader.result } : image) : [...shopEdit.images, { type: 'main', src: reader.result }] : [{ type: 'main', src: reader.result }] });
                        dispatch(fetchUploadMain({ token: localStorage.getItem('token'), body: formData }));
                    }
                }
            };
            reader.readAsDataURL(file);
        } catch {

        }
    }

    useEffect(() => {
        if (backgroundUploadStatus === 'success') {
            setShopEdit({ ...shopEdit, images: shopEdit.images.map(image => image.type === 'background' ? { ...image, src: backgroundUploadResult.url } : image) });
            dispatch(setUploadStatus({ type: 'background', status: null }));
        }
        if (mainUploadStatus === 'success') {
            setShopEdit({ ...shopEdit, images: shopEdit.images.map(image => image.type === 'main' ? { ...image, src: mainUploadResult.url } : image) });
            dispatch(setUploadStatus({ type: 'main', status: null }));
        }
    }, [backgroundUploadStatus, mainUploadStatus, dispatch, shopEdit, backgroundUploadResult, mainUploadResult]);

    const setElementState = (property, value) => {
        setShopEdit({ ...shopEdit, [property]: value });
    }

    return (
        <div>
            {
                status === 'loading' &&
                <div className='shop-page-container'>
                    <div className="shop-header">
                        <div className="shop-page-background-container loading">

                        </div>
                        <div className="shop-page-info-container">
                            <div className="shop-page-main-image">

                            </div>
                            <div className="shop-page-info-properties">

                            </div>
                        </div>
                    </div>
                    <div className="loading small-padding px30-height small-margin"></div>
                    <div className="loading small-padding px30-height small-margin"></div>
                    <div className="loading small-padding px30-height small-margin"></div>
                    <div className="loading small-padding px30-height small-margin"></div>
                    <div className="loading small-padding px30-height small-margin"></div>
                    <div className="loading small-padding px30-height small-margin"></div>
                    <div className="loading small-padding px30-height small-margin"></div>
                    <div className="loading small-padding px30-height small-margin"></div>
                    <div className="loading small-padding px30-height small-margin"></div>
                </div>
            }
            {
                status === 'error' &&
                <div className='error'>
                    Произошла ошибка во время загрузки страницы, пожалуйста, попробуйте обновить страницу
                </div>
            }
            {
                status === 'success' &&
                <div className='shop-page-container'>
                    {
                        shopEdit !== undefined &&
                        <div>
                            <div className="shop-header">
                                <input accept="image/*" type="file" className='none-display' ref={inputRef} onChange={fileSelected} />
                                <div className="shop-page-background-container">
                                    {
                                        backgroundUploadStatus !== 'loading' &&
                                        <ImageComponent src={shopEdit?.images?.find(image => image.type === 'background')?.src ? host.url + '/api/' + shopEdit?.images?.find(image => image.type === 'background')?.src : background} alt="" />
                                    }
                                    {
                                        backgroundUploadStatus === 'loading' &&
                                        <img src={loading} alt="" style={{ width: '20px', height: '20px', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />
                                    }
                                    {
                                        shopAction !== 'view' &&
                                        <button className="button edit-button-shop" onClick={() => { inputRef?.current?.click(); setTargetImage('background') }}>
                                            <img src={editIcon} alt="" style={{ width: '20px', height: '20px' }} />
                                            <span className='color-white small-left-margin' style={{ fontSize: '0.8em' }}>Изменить</span>
                                        </button>
                                    }
                                </div>
                                <div className="shop-page-info-container">
                                    <div className='shop-page-main-image-container'>
                                        <div className="shop-page-main-image">
                                            {
                                                mainUploadStatus !== 'loading' &&
                                                <ImageComponent src={shopEdit?.images?.find(image => image.type === 'main')?.src ? host.url + '/api/' + shopEdit?.images?.find(image => image.type === 'main')?.src : shopAvatar} alt="" />
                                            }
                                            {
                                                mainUploadStatus === 'loading' &&
                                                <img src={loading} alt="" style={{ width: '20px', height: '20px' }} />
                                            }
                                        </div>
                                        {
                                            shopAction !== 'view' &&
                                            <button className="button edit-button-shop" onClick={() => { inputRef?.current?.click(); setTargetImage('main') }}>
                                                <img src={editIcon} alt="" style={{ width: '20px', height: '20px' }} />
                                            </button>
                                        }
                                    </div>
                                    {
                                        (shopAction === 'view' && shopEdit.canEdit === true) &&
                                        <div className='shop-edit-button-container'>
                                            <button className="button edit-button-shop shop-edit-button-shop" onClick={() => { setShopAction('edit') }}>
                                                <img src={editIcon} alt="" style={{ width: '20px', height: '20px' }} />
                                                <span className='small-left-margin color-white'>Редактировать</span>
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                <div className="shop-components">
                                    <Component property={'title'} props={{ type: 'text', label: 'Название магазина', required: true }} data={shopEdit.title} action={shopAction} setElementState={setElementState} />
                                    <Component property={'note'} props={{ type: 'text', label: 'Записка магазина', required: false }} data={shopEdit.note} action={shopAction} setElementState={setElementState} />
                                    <Component property={'address'} props={{ type: 'text', label: 'Адрес магазина', required: false }} data={shopEdit.address} action={shopAction} setElementState={setElementState} />
                                    <Component property={'phone'} props={{ type: 'text', label: 'Номер телефона', required: false }} data={shopEdit.phone} action={shopAction} setElementState={setElementState} />
                                    <Component property={'email'} props={{ type: 'text', label: 'E-mail', required: false }} data={shopEdit.email} action={shopAction} setElementState={setElementState} />
                                    {
                                        !isComplated &&
                                        <p className="error loading">Некоторые обязательные поля (*) не заполнены, пожалуйста заполните их и попробуйте ещё раз</p>
                                    }
                                    {
                                        (shopAction !== 'view') &&
                                        <div className="component flex-center">
                                            <button onClick={onCancel} className='button form-button cancel'>Отмена</button>
                                            <button onClick={submit} className='button form-button accept'>{shopAction === 'add' ? 'Добавить' : 'Сохранить'}</button>
                                        </div>
                                    }
                                    {
                                        (shopAction === 'view') &&
                                        <div className="component flex-center small-padding full-width background-color-light rounded">
                                            <button className="button cancel small-padding full-width" onClick={() => { setShopEdit(); localStorage.removeItem('shop_id'); dispatch(setStatus(null)); dispatch(setShop()) }}>Выйти из магазина</button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        shops.length === 0 && shopEdit === undefined &&
                        <div className='flex-column align-center'>
                            <ImageComponent src={shopEmpty} className='flex-center' width={96} />
                            <p className='description flex-center'>Вы ещё не открыли магазин</p>
                        </div>
                    }
                    {
                        shops.length > 0 && shopEdit === undefined &&
                        <div className='small-margin'>
                            <h3 className='label-view'>Магазины</h3>
                            <p className='description' style={{ fontSize: '14px' }}>Выберите магазин</p>
                            {
                                shops.map((shop, index) =>
                                    <div key={index} className='list-item shops-list-item small-bottom-margin' onClick={() => { dispatch(setShop(shop)); localStorage.setItem('shop_id', shop._id); setShopEdit(shop) }}>
                                        <div className='shops-list-image'>
                                            <ImageComponent src={shop?.images?.find(image => image.type === 'main')?.src ? host.url + '/api/' + shop?.images?.find(image => image.type === 'main')?.src : shopAvatar} alt="" width={48} />
                                        </div>
                                        <div>
                                            <h3 className='shop-title-list'>{shop.title}</h3>
                                            <p className='shop-note-list'>{shop.note}</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    }
                    {
                        shopEdit === undefined &&
                        <div className='full-width flex-center'>
                            <button className='button background-color-light' onClick={() => { setShopAction('add'); setShopEdit({}) }}>
                                <ImageComponent src={addIcon} width={24} />
                                <p className='color-primary small-left-margin' style={{ margin: '5px' }}>Добавить магазин</p>
                            </button>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Shop