import React, { useState } from 'react';
import '../../styles.css';

const ImageComponent = ({ src, alt, height, width, style, className }) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleLoad = () => {
        setIsLoading(false);
    };

    const handleError = () => {
        setIsLoading(false); // Image failed to load
    };

    const finalClassname = (className ?? '') + ' slow-opacity';
    const finalStyles = style ? { ...style } : {};
    finalStyles.minHeight = isLoading ? height ?? width : height ?? 'auto';
    finalStyles.minWidth = isLoading ? width ?? height : width ?? 'auto';


    return (
        <img
            src={src}
            alt={alt}
            height={isLoading ? height ?? width : height ?? 'auto'}
            width={isLoading ? width ?? height : width ?? 'auto'}
            style={isLoading ? { ...finalStyles, opacity: 0 } : finalStyles}
            className={isLoading ? finalClassname + ' loading' : finalClassname} // Apply loading class if still loading
            onLoad={handleLoad}
            onError={handleError}
        />
    );
};

export default ImageComponent;
