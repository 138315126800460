import React from 'react';
import './Link.css';
import { setElement } from '../Element/element.slice';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';


/**
 * Text
 * @param {string} data - The current value of the element
 * @param {object} props - The props of the element
 * @param {string} action - The current action (add, edit, copy, view)
 * @param {string} property - The property name of the element
 * @param {function} setElementState - The function to set the element state
 * @returns A JSX element
 */
function Link({ data, props, action, property, setElementState }) {
  const dispatch = useDispatch(); 
  return (
    <div>
      {
        action === 'view' &&
        <div className='text-view'>
          <p className="label-view">{props?.label}</p>
          <NavLink to={data?.link} className="link-view">{data !== undefined ? data?.text : null}</NavLink>
        </div>
      }
      {
        (action === 'add' || action === 'edit' || action === 'copy') &&
        <div className='text-view flex-column'>
          <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
          <textarea value={data?.text ?? ''} onChange={e => setElementState ? setElementState(property, { ...data, text: e.target.value }) : dispatch(setElement({ property, value: { ...data, text: e.target.value } }))} />
          <label className='label-view'><span className='required'>*</span>Ссылка</label>
          <input value={typeof data?.link !== 'string' ? '' : data?.link} onChange={e => setElementState ? setElementState(property, { ...data, link: e.target.value }) : dispatch(setElement({ property, value: { ...data, link: e.target.value } }))} />
        </div>
      }
    </div>
  )
}

export default Link