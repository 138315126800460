import React from 'react';
import './Dialog.css';
const Dialog = ({ title, text, isOpen, onOk, onCancel, action = 'confirm' }) => {
    const handleOk = () => {
        onOk();
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <>
            {isOpen && (
                <div className="dialog-background">
                    <div className="dialog-container">
                        <div className="dialog-header small-margin">
                            <h3 className="dialog-title">{title}</h3>
                        </div>
                        <div className="dialog-body small-margin">
                            <p className="dialog-text">{text}</p>
                        </div>
                        <div className="dialog-footer">
                            <button className="button dialog-button cancel dialog-accept-button" style={{ borderRight: '1px solid white !important', color: 'var(--color-red) !important' }} onClick={handleOk}>
                                {action === 'confirm' ? 'Да' : 'Удалить'}
                            </button>
                            <button className="button dialog-button" onClick={handleCancel}>
                                {action === 'confirm' ? 'Нет' : 'Отмена'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Dialog;