import { React, useEffect, useRef } from 'react';
import './Home.css';
import homeBackground from '../../images/home-background.jpg';
import ImageComponent from '../ImageComponent/ImageComponent';
import notificationIcon from '../../images/notification.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ListElements from '../List/ListElements';
import { fetchFolderPage, setScrollTopPosition } from '../Layout/layout.slice';
import Widgets from '../Widgets/Widgets';
import leftIcon from '../../images/left-bold.png';

function Home() {
  const { name } = useSelector(state => state.user.user);
  const { pages, folderPages, folderPagesStatus } = useSelector(state => state.pages);
  const token = localStorage.getItem('token');

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const lid = new URLSearchParams(location.search).get('lid');
  useEffect(() => {
    if (lid !== null) {
      dispatch(fetchFolderPage(token + '?justPages=true&lid=' + lid));
    }
  }, [lid]);

  const dayTimeDefiner = () => {
    const date = new Date();
    let dayTime = '';
    if (date.getHours() >= 5 && date.getHours() < 12) {
      dayTime = 'Доброе утро';
    } else if (date.getHours() >= 12 && date.getHours() < 18) {
      dayTime = 'Добрый день';
    } else {
      dayTime = 'Добрый вечер';
    }
    return dayTime;
  }

  const { scrollTopPosition } = useSelector(state => state.pages.home);
  useEffect(() => {
    const mainElement = document.getElementsByTagName('main')[0];
    if (scrollTopPosition !== undefined) {
      setTimeout(() => mainElement.scrollTo(0, scrollTopPosition), 0);
    }

    homeElementContainerRef.current.addEventListener('click', () => dispatch(setScrollTopPosition(mainElement.scrollTop)));
  }, []);

  const { widgets } = useSelector(state => state.widgets);
  const homeElementContainerRef = useRef(null);

  const clientHeight = document.getElementsByTagName('main')[0]?.clientHeight;
  return (
    <div ref={homeElementContainerRef} className={location.search === '' ? 'up-animated' : ''}>
      {
        !location.search.includes('lid') &&
        <div className='header'>
          <div className="backgound-image-container">
            <ImageComponent src={homeBackground} alt='homeBackground' width='105%' />
          </div>
          <div className="home-header-text-container">
            <h1>{dayTimeDefiner()} <br /> {name?.split(' ')[0]}</h1>
          </div>
          <div className="notification-icon-container">
            <NavLink to='/notifications'>
              <ImageComponent src={notificationIcon} alt='notificationIcon' width='60%' />
            </NavLink>
          </div>
        </div>
      }
      <div className="main">
        <div className={'home-page-list-container ' + (location.search.includes('lid') ? 'left-animated' : '')} >
          <div className='align-center flex-start medium-margin-top'>
            {
              location.search.includes('lid') &&
              <div onClick={() => { navigate(-1) }}>
                <ImageComponent src={leftIcon} alt='leftIcon' width='30px' />
              </div>
            }
            <p className="user-action-buttons-title small-left-margin small-bottom-margin" style={{ marginTop: '0' }}>Разделы</p>
          </div>
          {
            (pages?.length > 0 && lid === null && folderPagesStatus !== 'loading') &&
            <ListElements items={pages?.filter(page => page.collection !== 'home' && page.collection !== 'user').map(page => { return { ...page, link: (page.isFolder ? '?lid=' + page._id.toString() : '/' + page.collection) } }).sort((a, b) => (a?.menuOrderLevel ?? 10) - (b?.menuOrderLevel ?? 10))} list={{ listTitle: 'title' }} hasLink={true} />
          }
          {
            (folderPages?.length > 0 && lid !== null && folderPagesStatus === 'success') &&
            <ListElements items={folderPages?.map(page => { return { ...page, link: (page.isFolder ? '?lid=' + page._id.toString() : '/' + page.collection) } }).sort((a, b) => a?.title?.toString()?.localeCompare(b?.title?.toString())).sort((a, b) => (a?.menuOrderLevel ?? 10) - (b?.menuOrderLevel ?? 10))} list={{ listTitle: 'title' }} hasLink={true} />
          }
          {
            folderPagesStatus === 'loading' &&
            <div className='list-loading-container'>
              <div className="list-item loading"></div>
              <div className="list-item loading"></div>
              <div className="list-item loading"></div>
              <div className="list-item loading"></div>
              <div className="list-item loading"></div>
              <div className="list-item loading"></div>
              <div className="list-item loading"></div>
              <div className="list-item loading"></div>
            </div>
          }
        </div>
        {
          !location.search.includes('lid') &&
          <div className='home-widgets-list-container' style={{ minHeight: widgets?.length > 0 ? ((clientHeight - 250 + 'px') ?? '0px') : 'auto' }}>
            {
              widgets?.length > 0 &&
              <p className="user-action-buttons-title small-left-margin small-bottom-margin" style={{ marginTop: '30px' }}>Виджеты</p>
            }
            <Widgets />
          </div>
        }
      </div>
    </div>
  )
}

export default Home