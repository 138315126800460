import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import List from './components/List/List';
import Element from './components/Element/Element';
import NotFound from './components/NotFound/NotFound';
import UniquePage from './components/UniquePage/UniquePage';

import Layout from './components/Layout/Layout';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function App() {
  const { pages, allPages, status } = useSelector(state => state.pages);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (status === 'success' && location.pathname === '/') {
      const pagesCopy = [...pages];
      const startPage = pagesCopy.sort((a, b) => (a?.menuOrderLevel !== undefined ? a?.menuOrderLevel : 5) - (b?.menuOrderLevel !== undefined ? b?.menuOrderLevel : 5))[0] !== undefined ? pagesCopy.sort((a, b) => (a?.menuOrderLevel !== undefined ? a?.menuOrderLevel : 5) - (b?.menuOrderLevel !== undefined ? b?.menuOrderLevel : 5))[0].collection : pagesCopy.filter(page => page.type.value === 'list')[0] === undefined ? pagesCopy[0].collection : pagesCopy.filter(page => page.type.value === 'list')[0].collection;
      navigate('/' + startPage);
    }
  }, [status, navigate, pages, location.pathname]);

  return (
    <div className="App" onContextMenu={(e) => { if (e?.target?.type !== 'text' && e?.target?.type !== 'textarea') { e.preventDefault() } }}>
      <Routes>
        <Route path='/' element={<Layout />}>
          {
            status === 'success' &&
            allPages.filter(page => page?.isFolder !== true).map((page, pIndex) => {
              switch (page.type.value) {
                case 'list':
                  return (
                    <Route key={pIndex} path={'/' + page.collection} >
                      <Route path={'*'} element={<List page={page} />} />
                      <Route path="" element={<List page={page} />} />
                    </Route>
                  );
                case 'unique':
                  return (
                    <Route key={pIndex} path={'/' + page.collection} element={<UniquePage component={page.component} />} />
                  );
                case 'element':
                  return (
                    <Route key={pIndex} path={'/' + page.collection} element={<Element action='view' elementPage={true} />} />
                  );
                default:
                  return (
                    <Route key={pIndex} path={'/' + page.collection} element={<List page={page} />} />
                  );
              }

            })
          }
          <Route path="/edit/">
            <Route path='*' element={<Element action='edit' />} />
          </Route>
          <Route path='/add/'>
            <Route path='*' element={<Element action='add' />} />
          </Route>
          <Route path='/view/'>
            <Route path='*' element={<Element action='view' />} />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export const host = {
  url: "https://seller.zardolu.ru",
};
export default App;
