import React from 'react'
import { useDispatch } from 'react-redux'
import { setElement } from '../Element/element.slice';

function Check({ props, data, action, property, setElementState }) {
    const dispatch = useDispatch();
    const randomCheckId = 'check' + Math.random().toString().substring(2);
    return (
        <div className='full-width'>
            <div className='text-view align-center'>
                <label className='label-view' htmlFor={randomCheckId} style={{ margin: '5px 0' }}><span className='required'>{(props.required && action !== 'view') ? '*' : ''}</span>{props.label}</label>
                <input id={randomCheckId} style={{ height: '20px', width: '20px', margin: '' }} type='checkbox' checked={data === true ? true : false} className="secondary-text-view" onChange={e => action === 'view' ? null : setElementState ? setElementState(property, e.target.checked) : dispatch(setElement({ property, value: e.target.checked }))} />
            </div>
        </div>
    )
}

export default Check