import React, { useEffect, useState } from 'react';
import { host } from '../../App';
import './Video.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUploadFile, setElement, setUploadStatus } from '../Element/element.slice';
import Loader from '../Loader/Loader';
import videoPlaceholder from '../../images/video-placeholder.mp4';


function Video({ props, data, action, property, setElementState }) {
    const dispatch = useDispatch();
    const upload = useSelector(state => state.element.uploads[property]);
    const { status: uploadStatus, result: uploadResult } = upload ?? { status: null, result: null };
    const handleVideoChange = (e) => {
        try {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                const formData = new FormData();
                formData.append('file', file);
                dispatch(fetchUploadFile({ token: localStorage.getItem('token'), body: formData, property: property }));
            };
            reader.readAsDataURL(file);
        } catch {

        }
    }
    useEffect(() => {
        if (uploadStatus === 'success') {
            if (setElementState) {
                setElementState(property, uploadResult.url);
                return;
            }
            dispatch(setElement({ property, value: uploadResult.url }));
            dispatch(setUploadStatus(null));
        }
    }, [uploadStatus, property, uploadResult, dispatch]);
    const [loading, setLoading] = useState(false);
    return (
        <div>
            {
                action === 'view' &&
                <div className='video-view' style={{ position: 'relative' }}>
                    <p className="label-view">{props.label}</p>
                    <video
                        src={data && host.url + '/api/' + data}
                        style={props.style ?? { minHeight: '256px' }}
                        onLoadStart={() => setLoading(true)}
                        onLoad={e => { setLoading(false); e.target.play(); }}
                        onCanPlay={e => { setLoading(false); e.target.play(); }}
                        autoPlay
                        loop
                        muted
                        onClick={e => e.target.paused === true ? (data !== undefined && e.target.play()) : e.target.pause()}
                    />
                    {
                        loading &&
                        <Loader loader={2} style={{ width: '40px', height: '40px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                    }
                </div>
            }
            {
                action !== 'view' &&
                <form onSubmit={e => e.preventDefault()} >
                    <input type="file" id='iconFileSelect' style={{ display: 'none' }} accept='video/*' onChange={handleVideoChange} />
                </form>
            }
            {
                ((uploadStatus === 'success' || uploadStatus === null) && (action === 'edit' || action === 'add' || action === 'copy')) &&
                <div className='video-view'>
                    <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
                    <div style={{ ...props.style, position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column', minWidth: '256px', minHeight: '256px' }}>
                        <video
                            src={data ? host.url + '/api/' + data : videoPlaceholder}
                            style={props.style ?? { minHeight: '256px' }}
                            autoPlay
                            loop
                            muted
                            onLoadStart={() => setLoading(true)}
                            onCanPlay={e => { setLoading(false); e.target.play(); }}
                            onClick={e => { e.target.paused === true ? e.target.play() : e.target.pause() }}
                        />
                        {
                            loading &&
                            <Loader loader={2} style={{ width: '40px', height: '40px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        }
                        <button className="button color-button-primary" style={{ marginTop: 'auto' }} onClick={e => document.getElementById('iconFileSelect').click()}>Выбрать</button>
                    </div>
                </div>
            }
            {
                uploadStatus === 'loading' && (action === 'edit' || action === 'add' || action === 'copy') &&
                <div className='video-view'>
                    <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
                    <div style={{ ...props.style, display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '256px', minHeight: '256px' }}>
                        <Loader loader={2} style={{ width: '40px', height: '40px' }} />
                    </div>
                </div>
            }
            {
                uploadStatus === 'error' && (action === 'edit' || action === 'add' || action === 'copy') &&
                <div className='video-view'>
                    <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', minWidth: '60px' }}>
                        <p className='error'>Не удалось загрузить видео</p>
                        <button className="button" onClick={e => { if (document.getElementById('iconFileSelect') !== undefined && document.getElementById('iconFileSelect') !== null) { document.getElementById('iconFileSelect').click() } }}>Выбрать картинку</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default Video