import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchPages = createAsyncThunk("pages/fetchPages", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/pages/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchFolderPage = createAsyncThunk("pages/fetchFolderPage", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/pages/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchAllPages = createAsyncThunk("pages/fetchAllPages", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/seller/pages/${parameters}`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
})

const pagesSlice = createSlice({
    name: "pages",
    initialState: {
        pages: [],
        folderPages: [],
        allPages: [],
        folderPagesStatus: null,

        home: {
            scrollTopPosition: undefined,
        },

        lastPage: '',
        status: null,
        error: null,
    },
    reducers: {
        setLastPage(state, { payload: lastPage }) {
            state.lastPage = lastPage;
        },

        setScrollTopPosition(state, { payload: position }) {
            state.home.scrollTopPosition = position;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPages.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            });
        builder
            .addCase(fetchPages.fulfilled, (state, action) => {
                state.pages = action.payload.data;
                state.allPages = state.allPages.concat(action.payload.data).filter((value, index, self) => self.findIndex(iValue => iValue._id === value._id) === index);
                state.error = null;
                state.status = "success";
            });
        builder
            .addCase(fetchPages.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });

        builder
            .addCase(fetchFolderPage.pending, (state, action) => {
                state.folderPagesStatus = "loading";
            });
        builder
            .addCase(fetchFolderPage.fulfilled, (state, action) => {
                state.folderPages = action.payload.data;
                state.allPages = state.allPages.concat(action.payload.data).filter((value, index, self) => self.findIndex(iValue => iValue._id === value._id) === index);
                state.folderPagesStatus = "success";
            });
        builder
            .addCase(fetchFolderPage.rejected, (state, action) => {
                state.folderPagesStatus = "error";
            });
        builder
            .addCase(fetchAllPages.pending, (state, action) => {
                state.status = "loading";
            });
        builder
            .addCase(fetchAllPages.fulfilled, (state, action) => {
                state.allPages = state.allPages.concat(action.payload.data).filter((value, index, self) => self.findIndex(iValue => iValue._id === value._id) === index);
            });
    },
});

export default pagesSlice.reducer;
export const { setLastPage, setScrollTopPosition } = pagesSlice.actions;