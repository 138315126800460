import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setElement } from '../Element/element.slice';
import { formatPhoneNumber } from '../User/user.slice';

function Phone({ data, props, action, property, setElementState }) {
    const dispatch = useDispatch();
    const handleInputCountryChange = (evt) => {
        let value = evt.target.value.replace(/\D/g, '');
        if (value[0] !== '+') {
            value = '+' + value.substring(0, 3);
        }
        if (setElementState) {
            setElementState(property, { ...data, country: value, fullNumber: value.replace('+', '') + data.number, formattedNumber: formatPhoneNumber(data.number, value, true) });
            return;
        }
        dispatch(setElement({ property: property, value: { ...data, country: value, fullNumber: value.replace('+', '') + data.number, formattedNumber: formatPhoneNumber(data.number, value, true) } }))
    };

    const handleInputPhoneChange = (evt) => {
        if (setElementState) {
            setElementState(property, { ...data, number: evt.target.value.replace(/\D/g, ''), formattedNumber: formatPhoneNumber(evt.target.value, data?.country ?? '+7', true), fullNumber: (data?.country ?? '+7')?.replace('+', '') + evt.target.value.replace(/\D/g, '') });
        }
        dispatch(setElement({ property: property, value: { ...data, number: evt.target.value.replace(/\D/g, ''), formattedNumber: formatPhoneNumber(evt.target.value, data?.country ?? '+7', true), fullNumber: (data?.country ?? '+7')?.replace('+', '') + evt.target.value.replace(/\D/g, '') } }))
    }
    useEffect(() => {
        if (data !== undefined) {
            if (data.country === undefined) {
                if (setElementState) {
                    setElementState(property, { ...data, country: '+7', fullNumber: '+7' + data.number, formattedNumber: formatPhoneNumber(data.number, '+7', true) });
                    return;
                }
                dispatch(setElement({ property: property, value: { ...data, country: '+7', fullNumber: '+7' + data.number, formattedNumber: formatPhoneNumber(data.number, '+7', true) } }))
            }
        }
    }, [data, dispatch, property, setElementState]);
    return (
        <div>
            {
                action === 'view' &&
                <div className='text-view'>
                    <p className="label-view">{props.label}</p>
                    <p className="secondary-text-view">{data !== undefined ? data.formattedNumber : null}</p>
                </div>
            }
            {
                (action === 'add' || action === 'edit' || action === 'copy') &&
                <div className='text-view flex-column'>
                    <p className="label-view"><span className='required'>{props.required ? '*' : ''}</span>{props.label}</p>
                    <div className='full-width'>
                        <input className='signin-input-country' style={{ width: '45px', paddingRight: '0px' }} type="text" value={data?.country?.toString().startsWith('+') ? data?.country : '+' + (data?.country ?? '7')} onChange={handleInputCountryChange} />
                        <input className='signin-input' style={{ width: 'auto', marginTop: '5px' }} type="text" placeholder="Номер телефона" value={data ? formatPhoneNumber(data?.number, data?.country ?? '+7') : ''} onChange={handleInputPhoneChange} />
                    </div>
                </div>
            }
        </div>
    )
}

export default Phone