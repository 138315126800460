import React, { useEffect, useState } from 'react';
import Chart from '../Chart/Chart';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIncomes, fetchSalesVolume } from './widgets.slice';
import { splitValue } from '../Number/Number';
import Loader from '../Loader/Loader';

function ClientRealizationsChart({ props }) {
    const { title: contragent } = props;
    const dispatch = useDispatch();
    const { status, items } = useSelector(state => state.widgets.salesVolumeChart);
    const { status: incomesStatus, items: incomesItems } = useSelector(state => state.widgets.incomes);
    const { shop } = useSelector(state => state.shop);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const shop_id = localStorage.getItem('shop_id');
        if (items.length === 0) {
            dispatch(fetchSalesVolume(token + '?shop_id=' + shop_id + '&pagination=false'));
        }

        if (incomesItems.length === 0) {
            dispatch(fetchIncomes(token + '?shop_id=' + shop_id + '&pagination=false'));
        }
    }, [dispatch]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const shop_id = localStorage.getItem('shop_id');
        dispatch(fetchSalesVolume(token + '?shop_id=' + shop_id + '&pagination=false'));
        dispatch(fetchIncomes(token + '?shop_id=' + shop_id + '&pagination=false'));
    }, [shop]);


    const [itemsState, setItemsState] = useState([]);
    useEffect(() => {
        if (status === 'success') {
            setItemsState(clientRealizationsAnalyze(items, contragent));
        }
    }, [status, items, contragent]);

    const clientRealizationsAnalyze = (sales, productName) => {
        const groupedSales = {};
        const today = new Date();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();

        // Russian names for months
        const monthsOfYear = [
            "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
            "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
        ];

        // Group sales by month and year
        sales.filter(sale => sale.contragent.text.trim() === contragent.trim()).forEach(sale => {
            const saleDate = new Date(sale.date);
            const monthYearKey = `${saleDate.getFullYear()}-${saleDate.getMonth()}`;

            if (!groupedSales[monthYearKey]) {
                groupedSales[monthYearKey] = {
                    totalRealizations: 0,
                    month: monthsOfYear[saleDate.getMonth()],
                    year: saleDate.getFullYear(),
                    monthYearDate: new Date(saleDate.getFullYear(), saleDate.getMonth(), 1),
                    daysInMonth: saleDate.getMonth() === currentMonth && saleDate.getFullYear() === currentYear
                        ? today.getDate() // Use current day if it's the current month
                        : new Date(saleDate.getFullYear(), saleDate.getMonth() + 1, 0).getDate() // Total days in month
                };
            }

            // Accumulate the weight of the specified product
            groupedSales[monthYearKey].totalRealizations += sale.sum; // Use the weight property
        });

        incomesItems.filter(income => income.contragent.text?.trim() === contragent.trim()).forEach(income => {
            const incomeDate = new Date(income.date);
            const monthYearKey = `${incomeDate.getFullYear()}-${incomeDate.getMonth()}`;

            if (!groupedSales[monthYearKey]) {
                groupedSales[monthYearKey] = {
                    totalIncomes: 0,
                    month: monthsOfYear[incomeDate.getMonth()],
                    year: incomeDate.getFullYear(),
                    monthYearDate: new Date(incomeDate.getFullYear(), incomeDate.getMonth(), 1),
                    daysInMonth: incomeDate.getMonth() === currentMonth && incomeDate.getFullYear() === currentYear
                        ? today.getDate() // Use current day if it's the current month
                        : new Date(incomeDate.getFullYear(), incomeDate.getMonth() + 1, 0).getDate() // Total days in month
                };
            }

            // Accumulate the weight of the specified product
            groupedSales[monthYearKey].totalIncomes = (groupedSales[monthYearKey].totalIncomes ?? 0) + income.sum;
        });

        // Prepare the result array
        const result = Object.values(groupedSales).map(({ totalRealizations, month, year, monthYearDate, totalIncomes }) => {
            return {
                monthYear: `${month} ${year}`,
                totalRealizations: (totalRealizations ?? 0)?.toFixed(0),
                monthYearDate: monthYearDate,
                totalIncomes: (totalIncomes ?? 0)?.toFixed(0),
            };
        });

        return result.sort((a, b) => a.monthYearDate - b.monthYearDate);
    };

    function CustomTooltip({ payload, label, active }) {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', color: '#0076FE', border: '1px solid var(--primary-border-color)', padding: '5px' }}>
                    <p style={{ padding: 0, margin: 0, marginBottom: '5px', color: 'var(--primary-color)', fontWeight: 'bold' }}>{payload[0]?.payload?.monthYear}</p>
                    <p style={{ padding: 0, margin: 0, marginBottom: '5px', color: '#F46565' }}>{'Реализации'}</p>
                    <p style={{ padding: 0, margin: 0, fontWeight: 'bold', color: '#F46565' }}>{splitValue(payload[1]?.value) + ' ₽'}</p>
                    <p style={{ padding: 0, margin: 0, marginBottom: '5px' }}>{'Оплаты'}</p>
                    <p style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>{splitValue(payload[0]?.value) + ' ₽'}</p>
                </div>
            );
        }

        return null;
    }
    const maxValue = Math.max(...itemsState.map(item => item["totalRealizations"]), ...itemsState.map(item => item["totalIncomes"]));

    return (
        <div className={'collection'}>
            <p className="label-view">{props.label}</p>
            {
                (status === "loading" || incomesStatus === "loading") &&
                <Loader style={{ height: '240px', width: '100%', marginBottom: '60px' }} />
            }
            {
                (status === "success" && incomesStatus === "success") &&
                <Chart
                    data={itemsState}
                    props={
                        {
                            lineDataKey: "totalIncomes",
                            lineDataKey2: "totalRealizations",
                            label: 'Реализация',
                            label2: 'Оплата',
                            legendFormatter: (value, entry, index) => value === 'totalIncomes' ? 'Оплата' : 'Реализация',
                            xAxisDataKey: "monthYear",
                            legend: true,
                            height: '350px',
                            CustomTooltip: CustomTooltip,
                            domain: [0, maxValue + 100 - (maxValue % 10)]
                        }
                    }
                />
            }
        </div>
    )
}

export default ClientRealizationsChart