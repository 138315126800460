import React, { useEffect, useState } from 'react';
import notFoundIcon from '../../images/not-found.png';
import ImageComponent from '../ImageComponent/ImageComponent';
import { useSelector } from 'react-redux';

function NotFound() {
  const { allPages } = useSelector(state => state.pages);
  const [showNotFound, setShowNotFound] = useState(false);

  useEffect(() => {
    const interval = setTimeout(() => {
      setShowNotFound(true);
    }, 2000);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  return (
    <div>
      {
        (showNotFound && allPages.length) > 0 &&
        <div className='align-center flex-center flex-column big-margin-top small-left-margin'>
          <ImageComponent src={notFoundIcon} alt='not found' height='96px' />
          <h4 className='text-secondary'><span style={{ fontSize: '2.5em' }}>404</span><br />Запрощенная страница не найдена или временно не доступна</h4>
        </div>
      }
    </div>
  )
}

export default NotFound