import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchServerLoad, setActiveFilterIndexServerLoad } from './widgets.slice';

import ImageComponent from '../ImageComponent/ImageComponent';
import './Widgets.css';

import expandListIcon from '../../images/list-expand.png';
import hideListIcon from '../../images/list-hide.png';
import Component from '../Component/Component';

function ServerLoad() {
  const filters = [
    {
      label: 'Сейчас',
      maxValue: 60,
    },
    {
      label: 'За час',
      maxValue: 60 * 60,
    },
    {
      label: 'За день',
      maxValue: 24 * 60 * 60,
    },
    {
      label: 'За месяц',
      maxValue: 24 * 60 * 60 * 30,
    },
  ];

  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState();

  const [itemsState, setItemsState] = useState([]);

  const { items, status, activeFilterIndex } = useSelector(state => state.widgets.serverLoad);

  useEffect(() => {
    if (status === 'success') {
      setItemsState(Object.entries(items).map(([key, value], index) => ({
        ...Object.entries(value).map(([key, oValue]) => ({ key, value: oValue.count })).reduce((acc, item) => { acc[item.key] = item.value; return acc }, {}),
        pathname: key,
        totalCount: Object.values(value).reduce((acc, item) => acc + (item?.count ?? 0), 0),
        id: index,
      })).sort((a, b) => b.totalCount - a.totalCount));
    }
  }, [status]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(fetchServerLoad(token + '?interval=' + filters[activeFilterIndex].maxValue));
  }, [activeFilterIndex]);

  const widgetListContainerRef = useRef(null);
  const [clientHeight, setClientHeight] = useState(document.getElementsByTagName('main')[0]?.clientHeight);
  const [scrollHeight, setScrollHeight] = useState(widgetListContainerRef.current?.scrollHeight);

  const setExpandedState = () => {
    const clientHeight = document.getElementsByTagName('main')[0]?.clientHeight;
    const scrollHeight = widgetListContainerRef.current?.scrollHeight;
    setClientHeight(clientHeight);
    setScrollHeight(scrollHeight);
    if (clientHeight !== undefined && scrollHeight !== undefined && scrollHeight > clientHeight) {
      setExpanded(false);
    } else {
      setExpanded();
    }
  }

  useEffect(() => {
    setExpandedState();
  }, [clientHeight, scrollHeight, status]);
  return (
    <div style={{ height: '100%' }}>
      <div className="header-filters flex-start align-center medium-margin-top overflow-x-auto">
        {
          filters.map((filter, index) => (
            <button
              className={"button header-filter-button small-font-size small-margin color-primary no-wrap-white-space" + (activeFilterIndex === index ? ' active-filter' : '')}
              key={index}
              onClick={() => { dispatch(setActiveFilterIndexServerLoad(index)); setTimeout(setExpandedState, 100) }}>
              {filter.label}
            </button>
          ))
        }
      </div>

      {
        status === 'success' &&
        <div>
          <div ref={widgetListContainerRef} className='widget-list-container' style={{ height: expanded === false ? (clientHeight - 400) + 'px' : 'fit-content', overflow: 'hidden' }}>
            <Component
              property={'totalCount'}
              data={itemsState.reduce((acc, item) => acc + item.totalCount, 0)}
              props={
                {
                  type: 'number',
                  label: 'Запросы',
                }
              }
              action={'view'}
            />

            <Component
              data={itemsState}
              props={
                {
                  type: 'collection',
                  label: 'Список роутов',
                  listTitle: 'pathname',
                  listSubtitles: ['totalCount'],
                  elements: {
                    pathname: {
                      type: 'text',
                      label: 'Путь',
                    },
                    totalCount: {
                      type: 'number',
                      label: 'Всего запросов',
                    },
                    GET: {
                      type: 'number',
                      label: 'GET',
                    },
                    POST: {
                      type: 'number',
                      label: 'POST',
                    },
                    PUT: {
                      type: 'number',
                      label: 'PUT',
                    },
                    PATCH: {
                      type: 'number',
                      label: 'PATCH',
                    },
                    DELETE: {
                      type: 'number',
                      label: 'DELETE',
                    },
                  }
                }
              }
              action={'view'}
              property={'serverLoad'}
            />

            {
              itemsState.length > 0 &&
              <p className='description small-font-size full-width justify-center align-center text-align-center'>Всего {itemsState.length} роутов</p>
            }
          </div>
          {
            expanded !== undefined &&
            <div className={'full-width flex-center ' + (expanded === false ? 'widget-expand-button-shadow' : '')}>
              <button
                className='button blur-background-color'
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                <ImageComponent src={expanded ? hideListIcon : expandListIcon} width={25} />
                {expanded ? 'Показать меньше' : 'Показать ещё'}
              </button>
            </div>
          }
        </div>
      }

      {
        status === 'loading' &&
        <p className='description small-font-size full-width justify-center align-center text-align-center'>Загрузка...</p>
      }
    </div>
  )
}

export default ServerLoad