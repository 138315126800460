import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, setSignout } from './user.slice';
import './User.css';
import { useNavigate } from 'react-router-dom';
//import { host } from '../../App';

function User() {
  const dispatch = useDispatch();
  const { user, status } = useSelector(state => state.user);
  const token = (user?.token !== null && user?.token !== undefined) ? user?.token : localStorage.getItem('token');
  useEffect(() => {
    if (token !== undefined) {
      dispatch(fetchUser(token + '?item=' + token));
    }
  }, [token, dispatch]);

  //const { pages } = useSelector(state => state.pages);

  const navigate = useNavigate();

  //const [notifications, setNotifications] = useState(JSON.parse(localStorage.getItem('notifications')));
  // const handleCheckbox = async (e, page) => {
  //   const isChecked = e.target.checked;
  //   const requestNotificationPermission = await Notification.requestPermission();
  //   if (requestNotificationPermission === 'granted') {
  //     const notifications = localStorage.getItem('notifications');
  //     if (notifications === null) {
  //       localStorage.setItem('notifications', JSON.stringify({ [page._id]: isChecked }));
  //       setNotifications({ [page._id]: isChecked });
  //     } else {
  //       const notificationsObj = JSON.parse(notifications);
  //       localStorage.setItem('notifications', JSON.stringify({ ...notificationsObj, [page._id]: isChecked }));
  //       setNotifications({ ...notificationsObj, [page._id]: isChecked });
  //     }
  //   } else {
  //     e.target.checked = false;
  //     alert('Не удалось получить разрешение на уведомления. Пожалуйста, включите уведомления в настройках.');
  //   }
  // }
  return (
    <div className='user-container'>
      {
        status === 'loading' &&
        <div className="user-information-container">
          <div className="user-information">
            <p className='user-name loading user-loading'> </p>
            <p className='user-phone loading user-loading'> </p>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
        </div>
      }
      {
        status === 'success' &&
        <div className="user-information-container">
          <div className="user-information">
            <p className='user-name'>{user?.name}</p>
            <p className='user-phone'>{user?.phone?.formattedNumber}</p>
          </div>
          

          {/* <div>
            <p className="user-action-buttons-title">Уведомления</p>
            {
              pages.filter(page => page?.type?.value === 'list').length > 0 &&
              <div className="user-action-buttons">
                {
                  pages.filter(page => page?.type?.value === 'list').map((page, pIndex) => (
                    <div key={pIndex} className="user-button-container">
                      <div className="button user-button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <img src={host.url + '/api/' + page.icon} alt="" width={30} height={30} />
                        <p style={{ margin: 0, marginLeft: '10px', color: 'var(--primary-color)' }}>{page.title}</p>
                        <input type="checkbox" style={{ height: '20px', width: '20px', marginLeft: 'auto' }} checked={notifications?.[page._id] === true} onChange={e => handleCheckbox(e, page)} />
                      </div>
                    </div>
                  ))
                }
              </div>
            }
          </div> */}
          <div className="user-action-buttons">
            <div className="user-button-container">
              <button className="button user-button user-button-red cancel" onClick={() => { dispatch(setSignout()); navigate('/login') }}>Выйти из аккаунта</button>
            </div>
          </div>
        </div>
      }
      {
        status === 'error' &&
        <div className="error">Произошла ошибка при загрузки страницы, попробуйте перегрузить страницу</div>
      }
    </div>
  )
}

export default User